<template>
  <div class="product__wrapper">
    <div class="product__content" v-if="product">
      <div class="product__title">
        <h2>{{ product.product_name }}</h2>
      </div>
      <div class="product__header">
        <div class="product__img">
          <img
            :src="
              product.img
                ? '/img/products/' + product.img
                : require('@/assets/0259a878942a099e46261bbd49e85608.jpg')
            "
            :alt="product.product_name"
          />
        </div>
        <div class="product__info">
          <h3>Характеристики</h3>
          <table border="1">
            <tr>
              <td>Вид:</td>
              <td>{{ product.product_name }}</td>
            </tr>
            <tr>
              <td>Размер:</td>
              <td>{{ product.size }}</td>
            </tr>
            <tr>
              <td>Длинна:</td>
              <td>{{ product.lgth }}</td>
            </tr>
            <tr>
              <td>Сорт:</td>
              <td>{{ product.sort }}</td>
            </tr>
            <tr>
              <td>Ед. измерения:</td>
              <td>{{ product.izm }}</td>
            </tr>
          </table>
        </div>
        <div class="product_main__price">
          <h4>
            {{
              "Цена: " +
              (product.price ? Number(product.price).toFixed(2) : 0) +
              " руб./" +
              product.izm
            }}
          </h4>
          <div class="products__count__all">
            <h5>Количество</h5>
            <div class="product__count">
              <button @click="func_count(false)">-</button>
              <input type="number" v-model="product.count" @change="func_check_input" />
              <button @click="func_count(true)">+</button>
            </div>
          </div>

          <div class="product__cart">
            <button
              :class="{ color__red: func_check_cart() }"
              @click="func_add_to_cart(func_check_cart())"
            >
              {{ func_check_cart() ? "Убрать из корзины" : "В корзину" }}
            </button>
          </div>
        </div>
      </div>
      <div class="product__description">
        <h3>Описание</h3>
        <p>{{ product.description || "Описание отсутсвует" }}</p>
      </div>
    </div>
    <div
      class="products__dop__list"
      v-if="products_dop_list && products_dop_list.length > 0"
    >
      <h3>Похожие товары</h3>
      <div class="products__list__dop">
        <Product
          v-for="item in products_dop_list"
          :key="item.product_id"
          :product="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "@/components/v-product.vue";
export default {
  props: {
    product_id: Number,
  },
  components: {
    Product,
  },
  data() {
    return {
      product: null,
      products_dop_list: [],
    };
  },
  watch: {
    PRODUCT_ID_STATE(value) {
      this.func_get_product_info(Number(value));
    },
  },
  methods: {
    func_get_product_info(product_id) {
      window
        .sendRequest({
          num: 11,
          param: {
            product_id: product_id,
          },
        })
        .then((result) => {
          if (result) {
            if (result.product) {
              this.product = result.product;
            }
            if (result.products_list) {
              this.products_dop_list = result.products_list;
            }
          }
        });
    },
    func_check_cart() {
      let arr = this.CART_STATE || [];
      let bool = false;
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (Number(item.product_id) === Number(this.product.product_id)) {
            bool = true;
          }
        });
      }
      return bool;
    },
    func_add_to_cart(bool_cart) {
      if (!bool_cart) {
        let arr = this.CART_STATE || [];
        let bool = false;
        if (arr.length > 0) {
          arr.forEach((item) => {
            if (Number(item.product_id) === Number(this.product.product_id)) {
              bool = true;
            }
          });
        }
        if (!bool) {
          arr.push(this.product);
          this.$store.commit("CHANGE_CART", arr);
        }
      } else {
        let arr = this.CART_STATE || [];
        arr.splice(
          arr.indexOf(
            arr.find((x) => Number(x.product_id) === Number(this.product.product_id))
          ),
          1
        );
        this.$store.commit("CHANGE_CART", arr);
      }
    },
    func_check_input() {
      if (this.product.count < 1 || this.product.count > 5000) {
        this.product.count = 1;
      }
    },
    func_count(bool) {
      if (bool) {
        if (this.product.count < 5000) {
          this.product.count++;
        }
      } else {
        if (this.product.count > 1) {
          this.product.count--;
        }
      }
    },
  },
  mounted() {
    if (this.product_id) {
      this.func_get_product_info(this.product_id);
    } else {
      this.$router.push("/catalog");
    }
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
    ...mapGetters(["PRODUCT_ID_STATE"]),
  },
};
</script>

<style>
.product__header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.product__header .product__img,
.product__header .product__info,
.product__header .product_main__price {
  flex-basis: 32%;
}

.product__header .product__info h3 {
  margin-bottom: 5px;
}
.product__header .product__img {
  height: auto;
}
.product__header .product__img img {
  width: 100%;
  height: 100%;
}
.product__header .product__info {
  text-align: center;
}
.product__header .product__info table {
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}
.product__header .product__info table td {
  padding: 8px;
  text-align: left;
}
.product__description {
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid #e2e2e2;
}
.product__header .product_main__price {
  padding: 20px;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}
.products__dop__list {
  margin-top: 40px;
}
.products__list__dop {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
</style>
