<template>
  <div class="order__wrapper">
    <div class="order__titile">
      <h2>Оформить заказ</h2>
    </div>
    <div class="order__table" v-show="CART_STATE && CART_STATE.length > 0 && !success">
      <table border="1">
        <tr>
          <th>ID товара</th>
          <th>Наименование</th>
          <th>Кол-во</th>
          <th>Цена</th>
          <th></th>
        </tr>
        <tr v-for="item in CART_STATE" :key="item.product_id">
          <td>{{ item.product_id }}</td>
          <td>
            {{ item.product_name + " " + item.size + "x" + item.lgth + " " + item.sort }}
          </td>
          <td>
            <div class="product__count">
              <button @click="func_count(item, false)">-</button>
              <input
                type="number"
                v-model="item.count"
                @change="func_check_input(item)"
              />
              <button @click="func_count(item, true)">+</button>
            </div>
          </td>
          <td>
            {{
              Number(Number(item.price) * Number(item.count)).toFixed(2) +
              " руб./" +
              item.izm
            }}
          </td>
          <td>
            <button
              title="Удалить позицию"
              class="btn_delete"
              @click="func_delete_cart(item)"
            >
              X
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div class="order__form" v-if="!success">
      <form @submit.prevent="func_order">
        <div class="form__left">
          <h4>Основная информация</h4>
          <div class="form-group">
            <label>Ваше имя *</label>
            <input type="text" required v-model="form.user_name" />
          </div>
          <div class="form-group">
            <label>Номер телефона *</label>
            <input type="text" required v-model="form.phone" />
          </div>
          <div class="form-group">
            <label>E-mail *</label>
            <input type="text" required v-model="form.email" />
          </div>
          <div class="form-group">
            <label>Комментарий </label>
            <textarea v-model="form.comment" rows="4"></textarea>
          </div>
        </div>
        <div class="form__right">
          <div class="form-group d-flex">
            <input type="checkbox" v-model="form.bool_dostavka" />
            <label>Доставка</label>
          </div>
          <div class="adress" v-if="form.bool_dostavka">
            <div class="form-group">
              <label>Адрес *</label>
              <input type="text" v-model="form.adress" />
            </div>
            <div class="form-group">
              <label>Дата доставки *</label>
              <input type="date" v-model="form.date_dostavka" />
            </div>
          </div>
          <div class="itog">
            <p>Итого: {{ func_itog() }} руб.</p>
            <button class="btn__order" :disabled="!CART_STATE || CART_STATE.length === 0">
              Оформить заказ
            </button>
            <p class="error" v-show="error">{{ error }}</p>
          </div>
        </div>
      </form>
    </div>
    <div class="success__wrapper" v-if="success">
      <h2>Спасибо!</h2>
      <h3>Ваш заказ принят и будет обработан в ближайшее время</h3>
      <img :src="require('@/assets/54276849__3.jpg')" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        user_name: "",
        phone: "",
        email: "",
        comment: "",
        bool_dostavka: true,
        adress: "",
        date_dostavka: "",
      },
      success: false,
      error: "",
    };
  },
  methods: {
    func_count(item, bool) {
      if (bool) {
        if (item.count < 5000) {
          item.count++;
        }
      } else {
        if (item.count > 1) {
          item.count--;
        }
      }
      let arr = this.CART_STATE;
      this.$store.commit("CHANGE_CART", arr);
    },
    func_check_input(item) {
      if (item.count < 1 || item.count > 5000) {
        item.count = 1;
      }
    },
    func_order() {
      this.error = "";
      if (this.form.bool_dostavka && (!this.form.adress || !this.form.date_dostavka)) {
        this.error = "Заполните поля доставки";
        return;
      }
      window
        .sendRequest({
          num: 12,
          param: {
            user_name: this.form.user_name,
            phone: this.form.phone,
            email: this.form.email,
            comment: this.form.comment,
            adress: this.form.adress,
            bool_dostavka: this.form.bool_dostavka,
            date_dostavka: this.form.date_dostavka,
            products_list: this.CART_STATE,
          },
        })
        .then((result) => {
          if (result && result.code === 200) {
            this.success = true;
            this.func_clear_form();
            this.$store.commit("CHANGE_CART", []);
            setTimeout(() => {
              this.$router.push("/");
            }, 3000);
          }
        });
    },
    func_clear_form() {
      this.form.user_name = this.form.phone = this.form.email = this.form.comment = this.form.adress = this.form.date_dostavka =
        "";
      this.form.bool_dostavka = true;
    },
    func_delete_cart(item) {
      let arr = this.CART_STATE || [];
      arr.splice(
        arr.indexOf(arr.find((x) => Number(x.product_id) === Number(item.product_id))),
        1
      );
      this.$store.commit("CHANGE_CART", arr);
    },
    func_itog() {
      let itog = 0;
      if (this.CART_STATE) {
        this.CART_STATE.forEach((item) => {
          if (item) {
            itog += Number(item.price) * Number(item.count);
          }
        });
      }
      return Number(itog).toFixed(2);
    },
  },
  mounted() {
    if (!this.CART_STATE) {
      this.$router.push("/catalog");
    }
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
  },
};
</script>

<style>
.order__table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.order__table table td,
.order__table table th {
  padding: 5px;
}
.order__table table td .btn_delete {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #5f4330;
  color: #fff;
  border: 0;
  transition: 0.5s;
  cursor: pointer;
}
.order__table table td .btn_delete:hover {
  opacity: 0.9;
}
.order__form {
  margin-top: 20px;
}
.order__form form {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.order__form form .form__left,
.order__form form .form__right {
  flex-basis: 45%;
}
.order__form form .form-group {
  margin-bottom: 10px;
}
.order__form form .form-group label {
  display: block;
  font-size: 14px;
}
.order__form form .form-group input,
.order__form form .form-group textarea {
  padding: 7px;
  margin-top: 4px;
  width: 100%;
}
.order__form form .form__right {
  box-shadow: 0 0 22px #e2e2e2;
  padding: 10px;
}
.d-flex {
  display: flex;
  align-items: center;
}
.order__form form .form__right input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 5px;
}
.itog {
  font-weight: 700;
}
.btn__order {
  padding: 8px;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
  background: url("/img/wood-board-5395628_1280.jpg");
  color: #fff;
}
.btn__order:hover:enabled {
  color: #e2e2e2;
  transform: scale(0.98);
}
.btn__order:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.success__wrapper {
  margin-top: 30px;
  text-align: center;
}
.success__wrapper img {
  width: 100%;
}
.error {
  font-size: 13px;
  text-align: center;
  color: red;
}
</style>
