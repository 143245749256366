<template>
  <div class="payment__wrapper">
    <h2 class="title">Оплата</h2>
    <div class="payment__content">
      <h4>Оплата наличными</h4>
      <p>
        Наличный расчет предусмотрен при доставке заказов нашим автотранспортом и
        передачей наличных водителю-экспедитору. Нужно лишь оформить заказ, согласовать
        дату и время поставки с менеджером. Когда сотрудник нашей компании привозит товар,
        клиент проверяет комплектацию: наименование, размер, количество, сорт по
        товарно-транспортной накладной, наличие чека и других необходимых документов,
        после этого принять товар и оплатить наличными водителю-экспедитору. На складе
        выдача товаров Пром-Лес производится после оплаты наличными.
      </p>
      <h4>Безналичный расчет</h4>
      <p>
        Если покупка в нашей фирме совершается организацией или иным юридическим лицом, то
        оплата производится по счету, который выставляет менеджер компании. Срок оплаты
        счета до 3 дней. При необходимости возможно продление периода оплаты счета - для
        этого нужно связаться со своим менеджером. Товар может, отгружается в течение
        суток после поступления денежных средств на счет фирмы, но только после
        согласования с менеджером даты и времени поставки (исключение - случаи оформления
        товаров в статусе покупки под заказ). При отгрузке продукции в регионы сроки
        устанавливаются из расчета времени, необходимого для доставки товара на наш склад
        (до 7 рабочих дней), затем до транспортной компании. Далее сроки доставки зависят
        от условий ТК.
      </p>
      <h4>Возврат денежных средств или обмен товара</h4>
      <p>
        Вернуть товары, приобретенные в нашей компании, можно в течение 3 дней без
        объяснения причин. Подлежат возврату только товары надлежащего качества, которые
        не были в использовании. Для оформления возврата необходимо:
      </p>
      <ul>
        <li>1. Заявление от покупателя на обмен товара или возврат денежных средств</li>
        <li>2. Кассовый и товарный чек</li>
        <li>3. Внешний вид, упаковка, должны быть сохранены</li>
      </ul>
      <p>
        Если все эти условия соблюдены, то покупатель самостоятельно привозит товар на
        склад Пром-Лес, где и производится возврат.
      </p>
      <p>
        Обмен товаров осуществляется в течение 7 дней после покупки. Также как и в случае
        возврата, целостность упаковки товара, условия хранения, документация, чеки,
        должны быть сохранены. Обмену и возврату не подлежат товары в разорванных
        упаковках, с выгоревшей упаковкой от солнца, с повреждениями пиломатериалов:
        сколы, царапины, вмятины, и.т.д.
      </p>
      <p>
        В случае обнаружения скрытых недостатков Товара, т.е. таких его недостатков,
        которые не могли быть обнаружены в ходе его надлежащей приемки, Покупатель обязан
        незамедлительно, но не позднее 15 дней от момента приемки Товара, уведомить
        Продавца о таких недостатках. Продавец в согласованные Сторонами сроки направляет
        своего представителя к месту складирования товара для его осмотра и составления
        акта. В случае возникновения споров о характере и причинах возникновения скрытых
        недостатков может быть проведена экспертиза. Все расходы по проведению экспертизы
        несет Покупатель.
      </p>
      <h4>Поставка товара под заказ</h4>
      <ul>
        <li>
          1. Покупатель направляет Продавцу заявку с указанием количества и ассортимента
          Товара, подлежащего заказу. А так же предварительно связавшись по тел. +7 (925)
          962-24-13
        </li>
        <li>
          2. Продавец, получив заявку Покупателя, направляет в адрес последнего письменное
          подтверждение возможности поставки и сроки, выставляет счет на оплату данной
          партии товара
        </li>
        <li>3. Клиент оплачивает счет любым из способов</li>
        <li>
          4. О дате поступления товара на склады готовой продукции продавец обязан
          уведомить Покупателя не позднее, чем за 3 (три) дня и согласовать с ним способы
          получения товара
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.payment__content {
  font-size: 13px;
  margin-top: 10px;
}
.payment__content ul {
  margin-left: 20px;
}
.payment__content ul:last-child {
  margin-top: 15px;
}
.payment__content h4 {
  font-size: 16px;
}
</style>
