<template>
  <div id="app">
    <Modal />
    <Header />
    <Menu />
    <div class="body__wrapper">
      <div class="left__bar">
        <CatalogMenu />
        <Contact />
      </div>
      <div class="center__bar">
        <router-view></router-view>
      </div>
      <div class="right_bar">
        <Cart />
        <Partner />
        <Info />
      </div>
    </div>
    <FormCall />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/v-header.vue";
import Menu from "@/components/v-menu.vue";
import CatalogMenu from "@/components/v-catalog-menu.vue";
import Cart from "@/components/v-cart.vue";
import Partner from "@/components/v-partner.vue";
import Info from "@/components/v-info.vue";
import Contact from "@/components/v-contact.vue";
import FormCall from "@/components/v-form-call.vue";
import Footer from "@/components/v-footer.vue";
import Modal from "@/components/v-modal.vue";

export default {
  name: "App",
  components: {
    Header,
    Menu,
    CatalogMenu,
    Cart,
    Partner,
    Info,
    Contact,
    FormCall,
    Footer,
    Modal,
  },
};
</script>

<style>
@import "css/style.css";
@import "css/media.css";

.body__wrapper {
  display: flex;
  justify-content: space-between;
}
.left__bar,
.right_bar {
  flex-basis: 20%;
}
.center__bar {
  flex-basis: 60%;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
p {
  margin: 15px 0;
}
.bold {
  font-weight: 700;
}
a {
  color: #5f4330;
}
</style>
