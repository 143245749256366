<template>
  <div class="admin__category__wrapper">
    <h2>Категории</h2>
    <div class="admin__category__content">
      <Progress v-show="progress" />
      <div class="admin__category__items">
        <div class="admin__category__left">
          <h4>Выберите категорию</h4>
          <ul>
            <li v-for="item in category_list" :key="item.category_list">
              <span @click="func_select_category(item)">{{ item.category_name }}</span>
              <button @click="func_disable_category(item)">
                {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
              </button>
              <ul v-if="item.arr_dop">
                <li v-for="item2 in item.arr_dop" :key="item2.category_id">
                  <span @click="func_select_category(item2)">
                    {{ item2.category_name }}
                  </span>
                  <button @click="func_disable_category(item2)">
                    {{ Number(item2.disable) ? "Восстановить" : "Удалить" }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="admin__category__right" v-if="selected_category">
          <button @click="func_clear_selected_category">Назад</button>
          <h4>Изменить категорию</h4>
          <div class="admin__category__edit">
            <form @submit.prevent="func_rename_category">
              <input type="text" v-model="edit_category.edit_name" required />
              <span>Изображение: {{ selected_category.img }}</span>
              <input
                type="file"
                ref="file"
                v-on:change="func_file_upload()"
                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
              />
              <button>Сохранить</button>
            </form>
          </div>
          <div
            class="admin__category__add_dop"
            v-if="selected_category && Number(selected_category.category_lvl) === 1"
          >
            <h4>Добавить подкатегорию</h4>
            <form @submit.prevent="func_add_dop_category">
              <input type="text" v-model="add_dop_category.category_name" required />
              <input
                required
                type="file"
                ref="file2"
                v-on:change="func_file_upload2()"
                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
              />
              <button>Сохранить</button>
            </form>
          </div>
        </div>
        <div class="admin__category__right" v-else>
          <h4>Добавить категорию</h4>
          <form @submit.prevent="func_add_category">
            <input type="text" v-model="add_category.category_name" required />
            <input
              required
              type="file"
              ref="file3"
              v-on:change="func_file_upload3()"
              accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            />
            <button>Сохранить</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      category_list: [],
      progress: true,
      selected_category: null,
      edit_category: {
        edit_name: "",
        file: null,
      },
      add_dop_category: {
        category_name: "",
        file: null,
      },
      add_category: {
        category_name: "",
        file: null,
      },
    };
  },
  methods: {
    func_clear_selected_category() {
      this.selected_category = null;
    },
    func_file_upload3() {
      if (this.$refs.file3) {
        this.add_category.file = this.$refs.file3.files[0];
      }
    },
    func_file_upload2() {
      if (this.$refs.file2) {
        this.add_dop_category.file = this.$refs.file2.files[0];
      }
    },
    func_file_upload() {
      if (this.$refs.file) {
        this.edit_category.file = this.$refs.file.files[0];
      }
    },
    func_select_category(item) {
      if (item) {
        this.edit_category.edit_name = item.category_name;
        this.selected_category = item;
      }
    },
    func_disable_category(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 17,
        param: {
          category_id: item.category_id,
          disable: item.disable,
        },
      });
    },
    func_get_category() {
      window
        .sendRequestAdmin({
          num: 16,
        })
        .then((result) => {
          if (result) {
            this.category_list = result;
            this.progress = false;
          }
        });
    },
    func_rename_category() {
      window
        .sendRequestAdmin({
          num: 18,
          param: {
            category_id: this.selected_category.category_id,
            category_name: this.edit_category.edit_name,
            img: this.edit_category.file,
            img_path: "category",
          },
        })
        .then(() => {
          this.selected_category = null;
          this.edit_category.edit_name = "";
          this.func_get_category();
        });
    },
    func_add_dop_category() {
      window
        .sendRequestAdmin({
          num: 19,
          param: {
            main_category_id: this.selected_category.category_id,
            category_name: this.add_dop_category.category_name,
            img: this.add_dop_category.file,
            img_path: "category",
          },
        })
        .then(() => {
          this.selected_category = null;
          this.add_dop_category.category_name = "";
          this.func_get_category();
        });
    },
    func_add_category() {
      window
        .sendRequestAdmin({
          num: 20,
          param: {
            category_name: this.add_category.category_name,
            img: this.add_category.file,
            img_path: "category",
          },
        })
        .then(() => {
          this.selected_category = null;
          this.add_category.category_name = "";
          this.func_get_category();
        });
    },
  },
  mounted() {
    this.func_get_category();
  },
};
</script>

<style>
.admin__category__content {
  position: relative;
  min-height: 150px;
  margin-top: 20px;
}
.admin__category__items {
  display: flex;
  flex-wrap: wrap;
}
.admin__category__left,
.admin__category__right {
  flex-basis: 50%;
}
.admin__category__left ul li {
  margin-top: 5px;
}
.admin__category__left ul {
  margin-left: 20px;
}
.admin__category__left ul li span {
  cursor: pointer;
}
.admin__category__left ul li button {
  margin-left: 10px;
}
.admin__category__left p {
  font-size: 12px;
}
.admin__category__edit {
  margin-top: 10px;
}
.admin__category__add_dop,
.admin__category__right h4 {
  margin-top: 10px;
}
</style>
