<template>
  <div class="admin__delivery__wrapper">
    <h2>Доставка</h2>
    <div class="admin__delivery__content">
      <Progress v-show="progress" />
      <table border="1">
        <thead>
          <tr>
            <th rowspan="2">Грузоподъемность транспорта</th>
            <th colspan="3">Стоимость доставки</th>
          </tr>
          <tr>
            <th>Стоимость подачи</th>
            <th>До 100 км</th>
            <th>Более 100 км</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in delivery"
            :key="item.delivery_id"
            @click="func_select_item(item)"
          >
            <td><img :src="'/img/delivery/' + item.img" /></td>
            <td>{{ Number(item.podacha).toFixed(2) }} р.</td>
            <td>{{ Number(item.do100).toFixed(2) }} р./км</td>
            <td>{{ Number(item.ot100).toFixed(2) }} р./км</td>
            <td>
              <button @click="func_disable(item)">
                {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="add__new__delivery">
        <h3>{{ selected_delivery ? "Изменить" : "Добавить" }} доставку</h3>
        <form @submit.prevent="func_add_delivery">
          <input type="number" placeholder="Подача" required v-model="form.podacha" />
          <input type="number" placeholder="до 100 км" required v-model="form.do100" />
          <input type="number" placeholder="от 100 км" required v-model="form.ot100" />
          <input
            v-if="!selected_delivery"
            type="file"
            ref="file"
            v-on:change="func_file_upload()"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            required
          />
          <input
            type="file"
            ref="file"
            v-on:change="func_file_upload()"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            v-else
          />
          <button>{{ selected_delivery ? "Изменить" : "Добавить" }}</button>
          <p v-show="selected_delivery">
            При изменение позиции если фото не требуется изменять, файл выбирать не
            следует, оставить пустым.
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      delivery: [],
      progress: true,
      form: {
        podacha: "",
        ot100: "",
        do100: "",
        file: null,
      },

      selected_delivery: null,
    };
  },
  methods: {
    func_clear_file() {
      let file = this.$refs.file;
      file.type = "text";
      file.type = "file";
    },
    func_file_upload() {
      if (this.$refs.file) {
        this.form.file = this.$refs.file.files[0];
      }
    },
    func_select_item(item) {
      if (item) this.selected_delivery = item;
      this.form.podacha = this.selected_delivery.podacha;
      this.form.do100 = this.selected_delivery.do100;
      this.form.ot100 = this.selected_delivery.ot100;
    },
    func_clear_form() {
      this.form.podacha = this.form.ot100 = this.form.do100 = "";
      this.form.file = null;
    },
    func_add_delivery() {
      window
        .sendRequestAdmin({
          num: 28,
          param: {
            podacha: this.form.podacha,
            do100: this.form.do100,
            ot100: this.form.ot100,
            img: this.form.file,
            img_path: "delivery",
            delivery_id:
              this.selected_delivery !== null ? this.selected_delivery.delivery_id : 0,
          },
        })
        .then(() => {
          this.func_clear_file();
          this.func_clear_form();
          this.func_get_delivery();
        });
    },
    func_get_delivery() {
      window
        .sendRequestAdmin({
          num: 26,
        })
        .then((result) => {
          if (result) {
            this.delivery = result;
            this.progress = false;
          }
        });
    },
    func_disable(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 27,
        param: {
          delivery_id: item.delivery_id,
          disable: item.disable,
        },
      });
    },
  },
  mounted() {
    this.func_get_delivery();
  },
};
</script>

<style>
.admin__delivery__content {
  position: relative;
  min-height: 150px;
}
.add__new__delivery {
  margin-top: 20px;
}
</style>
