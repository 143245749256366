<template>
  <div class="inform__wrapper">
    <h2 class="title">Полезная информация</h2>
    <div class="inform__content">
      <Progress v-show="progress" />
      <div class="inform__select" v-if="article">
        <div class="article__back">
          <button @click="func_get_article(-1)">К списку новостей</button>
        </div>
        <h2>{{ article.title }}</h2>
        <div class="article__text" v-html="article.article"></div>
      </div>
      <div class="inform__list" v-if="article_list.length > 0">
        <div
          class="article__item"
          v-for="item in article_list"
          :key="item.article_id"
          @click="func_get_article(item.article_id)"
        >
          <div class="article__title">{{ item.title }}</div>
          <div class="article__date">
            {{
              new Date(item.datetime).toLocaleDateString() +
              " в " +
              new Date(item.datetime).toLocaleTimeString()
            }}
          </div>
          <div
            class="article__text"
            v-text="item.article.substr(0, 250).replace(/<\/?[^>]+(>|$)/g, '')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    article_id: Number,
  },
  components: {
    Progress,
  },
  data() {
    return {
      articleId: null,
      article: null,
      article_list: [],
      progress: true,
    };
  },
  methods: {
    func_get_article(article_id) {
      if (this.articleId !== Number(article_id)) {
        this.articleId = article_id;
        this.$store.commit("CHANGE_ARTICLE", article_id);
      }
      if (Number(article_id) > 0) {
        this.article_list = [];
        this.progress = true;
        window
          .sendRequest({
            num: 7,
            param: {
              article_id,
            },
          })
          .then((result) => {
            if (result) {
              this.progress = false;
              this.article = result;
            }
          });
      } else {
        this.article = null;
        this.progress = true;
        window
          .sendRequest({
            num: 8,
          })
          .then((result) => {
            if (result) {
              this.article_list = result;
              this.progress = false;
            }
          });
      }
    },
  },
  mounted() {
    if (this.article_id) {
      this.articleId = this.article_id;
      this.func_get_article(this.articleId);
    } else {
      this.func_get_article();
    }
  },
  watch: {
    ARTICLE_STATE() {
      this.articleId = this.ARTICLE_STATE;
      this.func_get_article(this.ARTICLE_STATE);
    },
  },
  computed: {
    ...mapGetters(["ARTICLE_STATE"]),
  },
};
</script>

<style>
.inform__content {
  position: relative;
  min-height: 300px;
}
.inform__list {
  margin-top: 10px;
}
.article__item {
  margin-bottom: 15px;
  border-bottom: 1px solid #e2e2e2;
  padding: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.article__item:hover {
  box-shadow: 0 0 22px #c2c2c2;
}
.article__title {
  font-weight: 700;
  font-size: 18px;
}
.article__date {
  font-size: 13px;
}
.article__text {
  font-size: 14px;
  margin-top: 5px;
}
.article__back {
  margin: 20px 0;
}
.article__back button {
  padding: 5px 20px;
  border: 0;
  background: #5f4330;
  color: #fff;
  cursor: pointer;
  transition: 0.3;
}
.article__back button:hover {
  background: #6b5749;
}
.article__text ul {
  margin-left: 30px;
}
.article__text h2 {
  font-size: 18px;
}
.inform__select h2 {
  border-bottom: 1px solid #e2e2e2;
}
</style>
