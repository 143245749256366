<template>
  <div class="menu__wrapper">
    <input type="checkbox" name="toggle" id="menu" class="toggleMenu" />
    <label for="menu" class="toggleMenu"
      >Меню <img :src="require('@/assets/hamburger_button_menu_icon_155296.png')" alt=""
    /></label>
    <ul class="menu">
      <li @click="func_link(item.component)" v-for="item in menu" :key="item.menu_id">
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        { menu_id: 1, title: "О компании", component: "/about" },
        { menu_id: 2, title: "Каталог", component: "/catalog" },
        { menu_id: 3, title: "Отзывы", component: "/answers" },
        { menu_id: 7, title: "Галерея", component: "/gallery" },
        { menu_id: 4, title: "Оплата", component: "/payment" },
        { menu_id: 5, title: "Доставка", component: "/delivery" },
        { menu_id: 6, title: "Контакты", component: "/contact" },
      ],
    };
  },
  methods: {
    func_link(component) {
      if (this.$router.history.current.path !== component) {
        this.$router.push(component);
      }
      if (component === "/catalog") {
        this.$route.params.category_id = -1;
        this.$store.commit("CHANGE_CATEGORY", -1);
      }
    },
  },
};
</script>

<style>
.menu {
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  align-items: center;
  background: #5f4330;
}
.menu li {
  text-align: center;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  border-right: 1px solid #382727;
  transition: 0.5s;
  background: #4a3425;
  color: #fff;
}
.menu li:last-child {
  border-right: none;
}
.menu li:hover {
  background: #5f4330;
  color: #e2e2e2;
}
.menu__wrapper [type="checkbox"] {
  display: none;
}
.menu__wrapper label.toggleMenu {
  background: #4a3425;
  color: #fff;
  display: none;
  padding: 15px 40px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.menu__wrapper label.toggleMenu img {
  width: 30px;
  height: 30px;
}
</style>
