<template>
  <div class="partner__wrapper">
    <div class="partner__content" v-if="partners.length > 0">
      <div class="partner__header">
        <h4>Наши партнеры</h4>
      </div>
      <div class="partner__items">
        <Progress v-show="progress" />
        <a :href="item.link" v-for="item in partners" :key="item.partner_id">
          <img :src="'/img/partners/' + item.img" :alt="item.title" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      partners: [],
      progress: true,
    };
  },
  methods: {
    func_get_partners() {
      window
        .sendRequest({
          num: 13,
        })
        .then((result) => {
          if (result) {
            this.partners = result;
            this.progress = false;
          }
        });
    },
  },
  mounted() {
    this.func_get_partners();
  },
};
</script>

<style>
.partner__wrapper {
  margin-top: 25px;
  margin-right: 15px;
  box-shadow: 0 0 22px #c2c2c2;
}
.partner__wrapper .partner__header {
  padding: 10px;
  background: #5f4330;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  text-align: right;
}
.partner__items {
  padding: 5px;
  min-height: 150px;
  position: relative;
}
.partner__items a {
  width: 100%;
  display: block;
  margin-top: 10px;
  padding: 5px;
}
.partner__items a img {
  width: 100%;
  max-height: 150px;
}
</style>
