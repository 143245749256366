<template>
  <div class="catalog__wrapper">
    <h2 class="title">Каталог</h2>
    <div class="catalog__content">
      <Progress v-show="progress" />
      <Search />
      <h4>{{ category_name }}</h4>
      <div class="category__items" v-if="category">
        <div
          class="category__item"
          v-for="item in category_list"
          :key="item.category_id"
          @click="func_get_products(item.category_id)"
        >
          <div class="category__img">
            <img
              :src="
                item.img
                  ? '/img/category/' + item.img
                  : require('@/assets/0259a878942a099e46261bbd49e85608.jpg')
              "
              :alt="item.category_name"
            />
          </div>
          <div class="category__price">
            <p>
              от
              {{
                item.min_price ? Number(item.min_price).toFixed(2) : Number(0).toFixed(2)
              }}
              руб.
            </p>
          </div>
          <div class="category__name">
            {{ item.category_name }}
          </div>
        </div>
      </div>
      <div class="products__list" v-else>
        <label>Сортировать по</label>
        <select v-model="sort">
          <option value="1">возрастанию цены</option>
          <option value="2">убыванию цены</option>
        </select>
        <div class="products__items">
          <Product v-for="item in products_list" :key="item.product_id" :product="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
import Product from "@/components/v-product.vue";
import Search from "@/components/v-search.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Progress,
    Product,
    Search,
  },
  props: {
    category_id: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      categoryId: null,
      progress: true,
      category_list: [],
      category_name: null,
      category: true,
      products_list: [],
      sort_main: 1,
    };
  },
  methods: {
    func_sort() {
      if (Number(this.sort_main) === 1) {
        this.products_list.sort((a, b) => {
          if (Number(a.price) > Number(b.price)) return 1;
          else return -1;
        });
      } else {
        this.products_list.sort((a, b) => {
          if (Number(a.price) < Number(b.price)) return 1;
          else return -1;
        });
      }
    },
    func_get_products(category_id) {
      if (category_id > 0) {
        window
          .sendRequest({
            num: 9,
            param: {
              category_id,
            },
          })
          .then((result) => {
            if (result) {
              if (result.category_list) {
                this.category = true;
                this.category_list = result.category_list;
                this.category_name = result.category_name;
                this.progress = false;
              }
              if (result.products_list) {
                this.category = false;
                this.products_list = result.products_list.sort((a, b) => {
                  if (Number(a.price) > Number(b.price)) return 1;
                  else return -1;
                });
                this.category_name = result.category_name;
                this.progress = false;
              }
            }
          });
      } else {
        window
          .sendRequest({
            num: 2,
          })
          .then((result) => {
            if (result && result.category_list) {
              this.category = true;
              this.category_list = result.category_list;
              this.progress = false;
            }
          });
      }
    },
  },
  watch: {
    CATEGORY_STATE() {
      this.categoryId = this.CATEGORY_STATE;
      this.func_get_products(this.categoryId);
    },
  },
  computed: {
    ...mapGetters(["CATEGORY_STATE"]),
    sort: {
      get() {
        return this.sort_main;
      },
      set(value) {
        this.sort_main = value;
        this.func_sort();
      },
    },
  },
  mounted() {
    this.categoryId = this.category_id;
    this.func_get_products(this.categoryId);
  },
};
</script>

<style>
.products__list select {
  margin: 10px 5px;
  padding: 5px 10px;
}
.products__list select:focus {
  outline: none;
}
.catalog__content {
  position: relative;
  min-height: 250px;
}

.category__price {
  font-size: 12px;
}
.category__name {
  font-size: 12px;
}
.category__name p {
  margin: 0;
}
.catalog__content h4 {
  margin: 10px 0;
  border-bottom: 1px solid #c2c2c2;
}
.products__items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
</style>
