<template>
  <div class="admin__products__wrapper">
    <h2>Товары</h2>
    <div class="admin__products__content">
      <Progress v-show="progress" />
      <div class="admin__product__add">
        <h3>{{ selected_product ? "Изменить" : "Добавить" }} товар</h3>
        <p>Для изменения товара, необходимо кликнуть дважды по строке в таблице.</p>
        <form @submit.prevent="func_add_product">
          <div class="form-group">
            <label for="">Категория товара</label>
            <select v-model="product_new.category_id" required>
              <option
                v-for="item in category_list"
                :key="Number(item.category_id)"
                :value="Number(item.category_id)"
              >
                {{ item.category_name }}
              </option>
            </select>
          </div>
          <input
            type="text"
            placeholder="Наименование товара"
            v-model="product_new.product_name"
            required
          />
          <textarea
            placeholder="Описание"
            v-model="product_new.description"
            required
          ></textarea>
          <input type="number" placeholder="Цена" v-model="product_new.price" required />
          <input type="text" placeholder="Измерение" v-model="product_new.izm" required />
          <input type="text" placeholder="Сорт" v-model="product_new.sort" />
          <input type="text" placeholder="Размер" v-model="product_new.size" />
          <input type="text" placeholder="Длинна" v-model="product_new.lgth" />
          <input
            v-if="selected_product"
            type="file"
            ref="file"
            v-on:change="func_file_upload()"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
          />
          <input
            v-if="!selected_product"
            type="file"
            ref="file"
            v-on:change="func_file_upload()"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            required
          />
          <p v-show="selected_product">
            При изменение товара если фото не требуется изменять, файл выбирать не
            следует, оставить пустым.
          </p>
          <button>{{ selected_product ? "Изменить" : "Добавить" }}</button>
        </form>
      </div>
      <div class="admin__products__list">
        <h3>Список товаров</h3>
        <input
          type="text"
          class="search__input"
          v-model="search"
          placeholder="Поиск..."
        />
        <table border="1">
          <thead>
            <tr>
              <th>ID товара</th>
              <th>Наименование</th>
              <th>Цена</th>
              <th>Измерение</th>
              <th>Категория</th>
              <th>Сорт</th>
              <th>Размер</th>
              <th>Длинна</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="products_list">
            <tr
              @click="func_select_product(item)"
              v-for="item in products_list.filter((item) => {
                return (
                  item.product_name
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.category_name
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.price.toString().toLowerCase().includes(search.toLowerCase()) ||
                  item.size.toString().toLowerCase().includes(search.toLowerCase())
                );
              })"
              :key="item.product_id"
            >
              <td>{{ item.product_id }}</td>
              <td>
                {{ item.product_name }}
              </td>
              <td>{{ Number(item.price).toFixed(2) }}</td>
              <td>{{ item.izm }}</td>
              <td>{{ item.category_name }}</td>
              <td>{{ item.sort }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.lgth }}</td>
              <td>
                <button @click="func_delete_product(item)">
                  {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      progress: true,
      products_list: [],
      search: "",
      category_list: [],
      selected_product: null,
      product_new: {
        category_id: "",
        product_name: "",
        price: 0,
        izm: "",
        sort: "",
        size: "",
        lgth: "",
        description: "",
        file: null,
      },
    };
  },
  methods: {
    func_select_product(item) {
      if (item) {
        this.selected_product = item;
        this.product_new.category_id = Number(item.category_id);
        this.product_new.product_name = item.product_name;
        this.product_new.price = Number(item.price).toFixed(2);
        this.product_new.izm = item.izm;
        this.product_new.sort = item.sort;
        this.product_new.size = item.size;
        this.product_new.description = item.description;
        this.product_new.lgth = item.lgth;
        this.product_new.size = item.size;
      }
    },
    func_clear_file() {
      let file = this.$refs.file;
      file.type = "text";
      file.type = "file";
    },
    func_file_upload() {
      if (this.$refs.file) {
        this.product_new.file = this.$refs.file.files[0];
      }
    },
    func_get_category_list() {
      window
        .sendRequestAdmin({
          num: 14,
        })
        .then((result) => {
          if (result) {
            this.category_list = result;
          }
        });
    },
    func_add_product() {
      let json = {
        product_name: this.product_new.product_name,
        category_id: this.product_new.category_id,
        price: this.product_new.price,
        izm: this.product_new.izm,
        sort: this.product_new.sort,
        size: this.product_new.size,
        description: this.product_new.description,
        lgth: this.product_new.lgth,
        img: this.product_new.file,
        img_path: "products",
        product_id: this.selected_product ? this.selected_product.product_id : 0,
      };

      window
        .sendRequestAdmin({
          num: 15,
          param: json,
        })
        .then(() => {
          this.product_new.product_name = this.product_new.category_id = this.product_new.izm = this.product_new.sort = this.product_new.size = this.product_new.lgth = this.product_new.description =
            "";
          this.product_new.price = 0;
          this.func_clear_file();
          this.func_get_products();
        });
    },
    func_delete_product(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 13,
        param: {
          product_id: item.product_id,
          disable: item.disable,
        },
      });
    },
    func_get_products() {
      window
        .sendRequestAdmin({
          num: 12,
        })
        .then((result) => {
          if (result) {
            this.products_list = result;
            this.progress = false;
          }
        });
    },
  },
  mounted() {
    this.func_get_products();
    this.func_get_category_list();
  },
};
</script>

<style>
.admin__products__list {
  margin-top: 20px;
}
.admin__products__content {
  margin-top: 15px;
  position: relative;
  min-height: 150px;
}
.admin__products__content label {
  width: 100%;
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}
.admin__products__content select {
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
}
</style>
