<template>
  <div class="delivery__wrapper">
    <h2 class="title">Доставка</h2>
    <div class="delivery__content">
      <p>
        Наша компания 'Пром-Лес' осуществляет доставку и разгрузку по Москве, Московской
        области и других регионах РФ.
      </p>
      <p>Вы можете воспользоваться следующими способами приобретения нашей продукции:</p>
      <p class="bold">1. Самовывоз</p>
      <p>
        Если самовывоз производится после оплаты по безналичному расчету, водитель должен
        при себе иметь доверенность на свое имя, заверенной синей печатью фирмы получателя
      </p>
      <p>
        Забронируйте выбранный Вами товар через форму обратной связи или составьте заказ
        через корзину по электронной почте.
      </p>
      <p>
        В кратчайший срок с Вами свяжется наш менеджер для подтверждения заказа и
        дальнейшей его подготовки к вашему приезду.
      </p>
      <p class="bold">2. По телефону +7 (925) 962-24-13</p>
      <p>На все Ваши вопросы ответит наш квалифицированный менеджер-консультант.</p>
      <p class="bold">3. Приехав в наш офис/склад</p>
      <p>
        (схема проезда указана в разделе
        <router-link to="/contact">контакты</router-link>) в удобное для Вас время (с 9-00
        до 18-00) без выходных и праздничных дней, ознакомиться с нашей продукцией лично и
        приобрести её как Вам удобно.
      </p>
    </div>
    <div class="delivery__table" v-if="delivery.length > 0">
      <table border="1">
        <thead>
          <tr>
            <th rowspan="2">Грузоподъемность транспорта</th>
            <th colspan="3">Стоимость доставки</th>
          </tr>
          <tr>
            <th>Стоимость подачи</th>
            <th>До 100 км</th>
            <th>Более 100 км</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in delivery" :key="item.delivery_id">
            <td><img :src="'/img/delivery/' + item.img" /></td>
            <td>{{ Number(item.podacha).toFixed(2) }} р.</td>
            <td>{{ Number(item.do100).toFixed(2) }} р./км</td>
            <td>{{ Number(item.ot100).toFixed(2) }} р./км</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="delivery__content">
      <p>
        Для заказа товара с доставкой и оплатой наличными, предоплата не требуется.
        Необходимо лишь оформить заявку и согласовать с менеджером дату поставки и время.
      </p>
      <p>
        Доставка может осуществляться в день заказа (по согласованию с менеджером) или в
        любой другой день. К заказу прилагается подробная накладная для удобства сверки
        комплектации товара, наименование размер, количество, сорт, стоимость.
      </p>
      <p>Заказчик имеет возможность проверить качество товара.</p>
      <p>
        При частичном или полном отказе от заказа клиент согласовывает цену с менеджером и
        производит перерасчет стоимости заказа.
      </p>
      <p>
        Оплата товара осуществляется на месте, передачей денег водителю-экспедитору.
        Разгрузка на объекте в цену доставки не входит.
      </p>
      <p>Разгрузка на участке оплачивается отдельно, стоимость разгрузки договорная.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      delivery: [],
    };
  },
  methods: {
    func_get_gelivery() {
      window
        .sendRequest({
          num: 15,
        })
        .then((result) => {
          console.log(result);
          if (result) {
            this.delivery = result;
          }
        });
    },
  },
  mounted() {
    this.func_get_gelivery();
  },
};
</script>

<style>
.delivery__wrapper p {
  font-size: 13px;
}
.delivery__table {
  width: 100%;
}
.delivery__table table {
  width: 100%;
  border-collapse: collapse;
  border-color: #e2e2e2;
}
.delivery__table table th,
.delivery__table table td {
  font-size: 13px;
  padding: 10px;
}
.delivery__table table td:first-child {
  text-align: center;
}
.delivery__table table td img {
  width: 60%;
}
</style>
