<template>
  <div class="admin__call__wrapper">
    <div class="admin__call__header">
      <h2>Обратная связь</h2>
    </div>
    <div class="admin__call__content">
      <Progress v-show="progress" />
      <p>
        Если заявка не просмотрена она будет отображаться серым цветом. Для того, что бы,
        изменить статус заявки на просмотрен, необходимо кликнуть на строку.
      </p>
      <input type="text" class="search__input" v-model="search" placeholder="Поиск..." />
      <table border="1">
        <thead>
          <tr>
            <th>ID заявки</th>
            <th>Имя пользователя</th>
            <th>Номер телефона</th>
            <th>Дата и время</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="call_list">
          <tr
            v-for="item in call_list.filter((item) => {
              return (
                item.user_name.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.phone.toString().toLowerCase().includes(search.toLowerCase()) ||
                new Date(item.datetime)
                  .toLocaleDateString()
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            })"
            :key="item.call_id"
            :class="{ admin__call__visible: !Number(item.visible) }"
            @click="func_set_visible(item)"
          >
            <td>{{ item.call_id }}</td>
            <td>{{ item.user_name }}</td>
            <td>{{ item.phone }}</td>
            <td>
              {{
                new Date(item.datetime).toLocaleDateString() +
                " в " +
                new Date(item.datetime).toLocaleTimeString()
              }}
            </td>
            <td>
              <button @click="func_delete_call(item)">
                {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: { Progress },
  data() {
    return {
      progress: true,
      call_list: [],
      search: "",
    };
  },
  methods: {
    func_get_call() {
      window
        .sendRequestAdmin({
          num: 3,
        })
        .then((result) => {
          if (result) {
            this.call_list = result;
            this.progress = false;
            this.func_sort();
          }
        });
    },
    func_sort() {
      this.call_list.sort((a, b) => {
        if (Number(a.visible) > Number(b.visible)) {
          return 1;
        } else {
          return -1;
        }
      });
    },
    func_delete_call(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 4,
        param: {
          call_id: item.call_id,
          disable: item.disable,
        },
      });
    },
    func_set_visible(item) {
      if (item) {
        item.visible = 1;
      }
      window.sendRequestAdmin({
        num: 5,
        param: {
          call_id: item.call_id,
        },
      });
      this.func_sort();
    },
  },
  mounted() {
    this.func_get_call();
  },
};
</script>

<style>
.admin__call__content {
  position: relative;
  min-height: 150px;
  margin-top: 15px;
  font-size: 12px;
}
.admin__call__content table {
  margin-top: 10px;
  word-break: normal;
  border: 1px solid #222;
}
.admin__call__content table td,
.admin__call__content table th {
  padding: 5px;
  font-size: 12px;
}
.admin__call__content table button {
  width: 100px;
}

.admin__call__content table tbody tr {
  transition: 0.5s;
  cursor: pointer;
}
.admin__call__content table tbody tr:hover {
  background: #e2e2e2;
}
.admin__call__visible {
  background: #e2e2e2;
}
</style>
