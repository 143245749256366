var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin__answer__wrapper"},[_c('h2',[_vm._v("Отзывы")]),_c('div',{staticClass:"admin__answer__content"},[_c('Progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.progress),expression:"progress"}]}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search__input",attrs:{"type":"text","placeholder":"Поиск..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('table',{attrs:{"border":"1"}},[_vm._m(0),_c('tbody',_vm._l((_vm.answer_list.filter(function (item) {
            return (
              item.user_name.toString().toLowerCase().includes(_vm.search.toLowerCase()) ||
              item.user_email.toString().toLowerCase().includes(_vm.search.toLowerCase()) ||
              item.company_name
                .toString()
                .toLowerCase()
                .includes(_vm.search.toLowerCase()) ||
              item.answer.toString().toLowerCase().includes(_vm.search.toLowerCase()) ||
              new Date(item.datetime)
                .toLocaleDateString()
                .toLowerCase()
                .includes(_vm.search.toLowerCase())
            );
          })),function(item){return _c('tr',{key:item.answer_id},[_c('td',[_vm._v(_vm._s(item.answer_id))]),_c('td',[_vm._v(" "+_vm._s(new Date(item.datetime).toLocaleDateString() + " в " + new Date(item.datetime).toLocaleTimeString())+" ")]),_c('td',[_vm._v(_vm._s(item.user_name))]),_c('td',[_vm._v(_vm._s(item.user_email))]),_c('td',[_vm._v(_vm._s(item.company_name))]),_c('td',{attrs:{"title":item.answer}},[_c('span',[_vm._v("Наведите для просмотра отзыва")])]),_c('td',[_c('button',{on:{"click":function($event){return _vm.func_delete_answer(item)}}},[_vm._v(" "+_vm._s(Number(item.disable) ? "Восстановить" : "Удалить")+" ")])])])}),0)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("ID отзыва")]),_c('th',[_vm._v("Дата и время")]),_c('th',[_vm._v("Имя пользователя")]),_c('th',[_vm._v("Email пользователя")]),_c('th',[_vm._v("Компания")]),_c('th',[_vm._v("Отзыв")]),_c('th')])])}]

export { render, staticRenderFns }