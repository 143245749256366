<template>
  <div class="answers__wrapper">
    <h2 class="title">Отзывы</h2>
    <div class="answer__content">
      <div class="answer__form">
        <form @submit.prevent="func_set_answer">
          <div class="form-group">
            <input
              type="text"
              placeholder="Ваше имя *"
              v-model="form.user_name"
              required
            />

            <input
              type="email"
              placeholder="Email (не публикуется) *"
              required
              v-model="form.user_email"
            />
          </div>
          <input
            type="text"
            placeholder="Название компании"
            v-model="form.company_name"
          />
          <textarea
            rows="5"
            placeholder="Отзыв *"
            required
            v-model="form.answer"
          ></textarea>
          <button>Отправить</button>
          <p class="error" v-show="error">{{ error }}</p>
        </form>
      </div>
      <div class="answer__items">
        <Progress v-show="progress" />
        <div class="answer__item" v-for="item in answers_list" :key="item.answer_id">
          <div class="answer__title">
            <div class="title__img">
              <img
                :src="require('@/assets/2b5625e49eaef05a81fc84357a669b54.png')"
                alt=""
              />
            </div>
            <div class="title__info">
              <div class="user__name">
                {{ item.user_name }}
              </div>
              <div class="date__time">
                {{
                  new Date(item.datetime).toLocaleDateString() +
                  " в " +
                  new Date(item.datetime).toLocaleTimeString()
                }}
              </div>
            </div>
          </div>
          <div class="answer__text">
            <h5>{{ item.company_name }}</h5>
            <p>{{ item.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      form: {
        user_name: "",
        user_email: "",
        answer: "",
        company_name: "",
      },
      progress: true,
      error: "",
      answers_list: [],
    };
  },
  methods: {
    func_get_answers() {
      window
        .sendRequest({
          num: 3,
        })
        .then((result) => {
          if (result) {
            this.answers_list = result;
            this.progress = false;
          }
        });
    },
    func_set_answer() {
      if (this.form.user_name && this.form.user_email && this.form.answer) {
        window
          .sendRequest({
            num: 4,
            param: {
              user_name: this.form.user_name,
              user_email: this.form.user_email,
              company_name: this.form.company_name,
              answer: this.form.answer,
            },
          })
          .then((result) => {
            if (result) {
              this.answers_list.unshift(result);
              this.form.user_name = this.form.user_email = this.form.company_name = this.form.answer =
                "";
            }
          });
      } else {
        this.error = "Заполните поля";
      }
    },
  },
  mounted() {
    this.func_get_answers();
  },
};
</script>

<style>
.answer__content {
  margin-top: 10px;
}
.answer__form {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 20px;
}
.answer__form input,
.answer__form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #c2c2c2;
}
.answer__form input:focus,
.answer__form textarea:focus {
  outline: none;
}
.answer__form .form-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.answer__form .form-group input {
  width: 49%;
}
.answer__form button {
  padding: 10px 20px;
  border: none;
  background: #5f4330;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
}
.answer__form button:hover {
  background: #362a21;
}
.answer__items {
  position: relative;
}
.answer__item {
  margin-top: 10px;
  border-bottom: 1px solid #e2e2e2;
}
.answer__title {
  display: flex;
}
.title__img {
  width: 30px;
  display: flex;
  height: 30px;
  margin-right: 10px;
}
.title__img img {
  width: 100%;
  object-position: center;
  object-fit: cover;
  height: 100%;
}
.title__info {
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.user__name {
  font-weight: 700;
}
.answer__text {
  margin-top: 10px;
}
.answer__text h5 {
  margin: 0;
  padding: 0;
}
.answer__text p {
  margin-top: 5px;
  font-size: 13px;
}
</style>
