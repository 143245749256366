<template>
  <div class="info__wrapper">
    <div class="info__content">
      <div class="info__header">
        <h4>Полезная информация</h4>
      </div>
      <div class="info__items">
        <Progress v-show="progress" />
        <ul v-if="articles">
          <li
            @click="func_show_article(article.article_id)"
            v-for="article in articles"
            :key="article.article_id"
          >
            {{ article.title }}
          </li>
        </ul>
        <button class="article__button" @click="func_show_article(-1)">
          Посмотреть все
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      progress: true,
      articles: [],
    };
  },
  methods: {
    func_get_articles() {
      window
        .sendRequest({
          num: 6,
        })
        .then((result) => {
          if (result) {
            this.articles = result;
            this.progress = false;
          }
        });
    },
    func_show_article(article_id) {
      this.$store.commit("CHANGE_ARTICLE", article_id);
      if (this.$router.history.current.path !== "/info") {
        this.$router.push("/info");
      }
      if (article_id) {
        this.$route.params.article_id = Number(article_id);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  mounted() {
    this.func_get_articles();
  },
};
</script>

<style>
.info__wrapper {
  margin-top: 25px;
  margin-right: 15px;
  min-height: 150px;
  box-shadow: 0 0 22px #c2c2c2;
}
.info__wrapper .info__header {
  padding: 10px;
  background: #5f4330;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  text-align: right;
}
.info__items {
  padding: 10px;
  position: relative;
  min-height: 100px;
}
.info__items ul {
  list-style-type: none;
}
.info__items ul li {
  margin-bottom: 10px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: underline;
}
.info__items ul li:hover {
  color: #c2c2c2;
}
.article__button {
  padding: 10px 20px;
  border: none;
  background: #5f4330;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  display: block;
  margin: 0 auto;
}
.article__button:hover {
  background: #362a21;
}
</style>
