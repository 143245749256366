<template>
  <div class="header__content">
    <div class="header__wrapper">
      <div class="header__item">
        <router-link to="/"
          ><img :src="require('@/assets/logo.png')" alt="" class="logo"
        /></router-link>
      </div>
      <div class="header__item">
        <div class="info">
          <h2>
            <img
              :src="require('@/assets/Screenshot_20200107_155732.png')"
              alt=""
            />Ежедневно с 8:00 до 22:00
          </h2>
          <h2>
            <img :src="require('@/assets/social-icon-email.png')" alt="" />
            info@пром-лес.рф
          </h2>
          <h4>Московская область, г. Мытищи ул. Коммунистическая 24 с-14</h4>
        </div>
      </div>
      <div class="header__item">
        <div class="phone">
          <h2>+7 (925) 962 24 13</h2>
          <button class="btn__call" @click="func_show_modal">Заказать звонок</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    func_show_modal() {
      this.$store.commit("CHANGE_MODAL", true);
    },
  },
};
</script>

<style>
.header__content {
  background: url("/img/banner (13).jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.header__content:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  content: "";
}
.header__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  height: 30vh;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.header__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 32%;
}
.header__item img {
  width: 80%;
  margin-right: 5px;
}
.info {
  text-align: center;
}
.info h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.info h2 img {
  width: 30px;
}
.info h4 {
  margin-top: 20px;
}
.phone {
  text-align: right;
}
.phone h2 {
  font-size: 35px;
}
.btn__call {
  margin-top: 10px;
  background: url("/img/wood-board-5395628_1280.jpg");
  color: #fff;
  padding: 10px;
  border: 0;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 0 22px black;
  cursor: pointer;
  transition: color 0.3s;
}
.btn__call:hover {
  color: #e2e2e2;
  transform: scale(0.98);
}
</style>
