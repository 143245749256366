import axios from 'axios'


window.sendRequest = function(json) {
    let fd = new FormData();
    fd.append('data', JSON.stringify(json));
    for (const param in json.params) {
        if (typeof json.params[param] === 'object') {
            fd.append('file', json.params[param]);
        }
    }
    return new Promise(function(resolve, reject) {
        axios({
                method: "post",
                url: "../backEnd/index.php", //release
                // url: "http://localhost:3000/backEnd/index.php", // debug
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: fd,
            })
            .then((response) => {
                if (response) {
                    if (response.data.code === 1) {
                        console.error(response.data, json);
                        resolve(response.data || null);
                    } else {
                        resolve(response.data || null);
                    }
                }
            })
            .catch(function(error) {
                console.error(error);
                reject({ status: 1, errorText: error });
            });
    });
}

window.sendRequestAdmin = function(json) {
    let fd = new FormData();
    fd.append('data', JSON.stringify(json));
    for (const param in json.param) {
        if (typeof json.param[param] === 'object') {
            fd.append('file', json.param[param]);
        }
    }
    return new Promise(function(resolve, reject) {
        axios({
                method: "post",
                url: "../backEnd/admin.php", //localhost
                // url: "http://localhost:3000/backEnd/admin.php", // debug
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: fd,
            })
            .then((response) => {
                if (response) {
                    if (response.data.code === 1) {
                        console.error(response.data, json);
                        resolve(response.data || null);
                    } else {
                        resolve(response.data || null);
                    }
                }
            })
            .catch(function(error) {
                console.error(error);
                reject({ status: 1, errorText: error });
            });
    });
}