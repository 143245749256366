<template>
  <div class="progress__wrapper">
    <img :src="require('@/assets/6.gif')" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style>
.progress__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 9999999999;
}
.progress__wrapper img {
  width: 50px;
}
</style>
