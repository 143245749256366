<template>
  <div class="contact__wrapper">
    <div class="contact__header">
      <img :src="require('@/assets/ContBlockImg.png')" alt="" />
      <h4>Контакты</h4>
    </div>
    <div class="contact__content">
      <p>Московская область, г. Мытищи ул. Коммунистическая 24 с-14</p>
      <a href="tel:89259622413">+7 (925) 962-24-13</a>
      <a href="tel:89850686067">+7 (985) 068-60-67</a>
      <p>
        <img :src="require('@/assets/social-icon-email.png')" /> Email:
        <a href="">info@пром-лес.рф</a>
      </p>
      <p>
        <img
          :src="
            require('@/assets/png-transparent-computer-icons-google-calendar-time-attendance-clocks-calendar-date-others-calendar-text-orange.png')
          "
        />Пн-Вс: 8:00 - 20:00
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.contact__wrapper {
  margin-top: 25px;
  margin-left: 15px;
  min-height: 150px;
  box-shadow: 0 0 22px #c2c2c2;
}
.contact__wrapper .contact__header {
  padding: 10px;
  background: #5f4330;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  text-align: right;
}
.contact__wrapper .contact__header img {
  position: absolute;
  left: 10px;
  width: 55px;
}
.contact__content {
  padding: 10px;
  margin-top: 5px;
}
.contact__content p {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.contact__content a {
  display: block;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #5f4330;
}
.contact__content p a {
  font-size: 14px;
  margin-top: 0;
  display: inline;
}
.contact__content p img {
  width: 25px;
  margin-right: 5px;
}
</style>
