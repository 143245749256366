<template>
  <div class="contactы__wrapper">
    <h2 class="title">Контакты</h2>
    <div class="contact__text">
      <h4>Мы находимся:</h4>
      <p>Московская область, г. Мытищи ул. Коммунистическая 24 с-14</p>
      <a href="tel:89259622413">+7 (925) 962-24-13</a>
      <a href="tel:89850686067">+7 (985) 068-60-67</a>
      <p>
        <img :src="require('@/assets/social-icon-email.png')" /> Email:
        <a href="">info@пром-лес.рф</a>
      </p>
      <p>
        <img
          :src="
            require('@/assets/png-transparent-computer-icons-google-calendar-time-attendance-clocks-calendar-date-others-calendar-text-orange.png')
          "
        />Пн-Вс: 8:00 - 20:00
      </p>
    </div>
    <div class="contact__map" id="map"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    let map = document.createElement("script");
    map.setAttribute("type", "text/javascript");
    map.setAttribute("charset", "utf-8");
    map.setAttribute("async", "true");
    map.setAttribute(
      "src",
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Ad63d1cc74acd8dbb29eda0b09ab7d84b487cc77f7eccfafe2ec80342e48da51d&amp;width=100%25&amp;height=456&amp;lang=ru_RU&amp;scroll=true"
    );
    document.querySelector("#map").appendChild(map);
  },
};
</script>

<style>
.contact__text {
  font-size: 13px;
}
.contact__text a {
  display: block;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #5f4330;
}
.contact__text p a {
  font-size: 14px;
  margin-top: 0;
  display: inline;
}
.contact__text p img {
  width: 25px;
  margin-right: 5px;
}
</style>
