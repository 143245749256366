import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        cart: [],
        search: '',
        article_id: null,
        category_id: null,
        product_id: null,
        modal: false,
        admin_auth: false,

    },
    mutations: {
        CHANGE_MODAL: (state, modal) => {
            state.modal = modal
        },
        CHANGE_CART: (state, cart) => {
            state.cart = cart
        },
        CHANGE_SEARCH: (state, search) => {
            state.search = search
        },
        CHANGE_ARTICLE: (state, article_id) => {
            state.article_id = article_id
        },
        CHANGE_CATEGORY: (state, category_id) => {
            state.category_id = category_id;
        },
        CHANGE_PRODUCT_ID: (state, product_id) => {
            state.product_id = product_id;
        },
        CHANGE_ADMIN_AUTH: (state, auth) => {
            state.admin_auth = auth;
        },
    },
    actions: {
        TOGGLE_MODAL({ commit }, modal) {
            commit('CHANGE_MODAL', modal)
        },
        TOGGLE_CART({ commit }, cart) {
            commit('CHANGE_CART', cart)
        },
        TOGGLE_SEARCH({ commit }, search) {
            commit('CHANGE_SEARCH', search)
        },
        TOGGLE_ARTICLE({ commit }, article_id) {
            commit('CHANGE_ARTICLE', article_id)
        },
        TOGGLE_CATEGORY({ commit }, category_id) {
            commit('CHANGE_CATEGORY', category_id)
        },
        TOGGLE_PRODUCT_ID({ commit }, product_id) {
            commit('CHANGE_PRODUCT_ID', product_id)
        },
        TOGGLE_ADMIN_AUTH({ commit }, auth) {
            commit('CHANGE_ADMIN_AUTH', auth)
        },
    },
    getters: {
        MODAL_STATE(state) {
            return state.modal;
        },
        CART_STATE(state) {
            return state.cart;
        },
        SEARCH_STATE(state) {
            return state.search;
        },
        ARTICLE_STATE(state) {
            return state.article_id;
        },
        CATEGORY_STATE(state) {
            return state.category_id;
        },
        PRODUCT_ID_STATE(state) {
            return state.product_id;
        },
        ADMIN_AUTH_STATE(state) {
            return state.admin_auth;
        }
    }
});

export default store;