<template>
  <div class="product__item">
    <div class="product__img" @click="func_show_product">
      <img
        :src="
          product.img
            ? '/img/products/' + product.img
            : require('@/assets/0259a878942a099e46261bbd49e85608.jpg')
        "
        alt=""
      />
    </div>
    <div
      @click="func_show_product"
      class="product__title"
      :title="
        product.product_name +
        ' ' +
        product.size +
        (product.lgth ? 'x' + product.lgth : '') +
        ' ' +
        product.sort
      "
    >
      {{
        (
          product.product_name +
          " " +
          product.size +
          (product.lgth ? "x" + product.lgth : "") +
          " " +
          product.sort
        ).length > 300
          ? (
              product.product_name +
              " " +
              product.size +
              (product.lgth ? "x" + product.lgth : "") +
              " " +
              product.sort
            ).substr(0, 300)
          : product.product_name +
            " " +
            product.size +
            (product.lgth ? "x" + product.lgth : "") +
            " " +
            product.sort
      }}
    </div>
    <div class="product__price">
      {{
        (product.price ? Number(product.price).toFixed(2) : 0) + " руб. за " + product.izm
      }}
    </div>
    <div class="product__count">
      <button @click="func_count(false)">-</button>
      <input type="number" v-model="product.count" @change="func_check_input" />
      <button @click="func_count(true)">+</button>
    </div>
    <div class="product__cart">
      <button
        :class="{ color__red: func_check_cart() }"
        @click="func_add_to_cart(func_check_cart())"
      >
        {{ func_check_cart() ? "Убрать из корзины" : "В корзину" }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    product: Object,
  },
  data() {
    return {};
  },
  methods: {
    func_add_to_cart(bool_cart) {
      if (!bool_cart) {
        let arr = this.CART_STATE || [];
        let bool = false;
        if (arr.length > 0) {
          arr.forEach((item) => {
            if (Number(item.product_id) === Number(this.product.product_id)) {
              bool = true;
            }
          });
        }
        if (!bool) {
          arr.push(this.product);
          this.$store.commit("CHANGE_CART", arr);
        }
      } else {
        let arr = this.CART_STATE || [];
        arr.splice(
          arr.indexOf(
            arr.find((x) => Number(x.product_id) === Number(this.product.product_id))
          ),
          1
        );
        this.$store.commit("CHANGE_CART", arr);
      }
    },
    func_count(bool) {
      if (bool) {
        if (this.product.count < 5000) {
          this.product.count++;
        }
      } else {
        if (this.product.count > 1) {
          this.product.count--;
        }
      }
    },
    func_check_cart() {
      let arr = this.CART_STATE || [];
      let bool = false;
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (Number(item.product_id) === Number(this.product.product_id)) {
            bool = true;
          }
        });
      }
      return bool;
    },
    func_check_input() {
      if (this.product.count < 1 || this.product.count > 5000) {
        this.product.count = 1;
      }
    },
    func_show_product() {
      this.$store.commit("CHANGE_PRODUCT_ID", this.product.product_id);
      if (this.$router.history.current.path !== "/product") {
        this.$router.push("/product");
      }
      this.$route.params.product_id = Number(this.product.product_id);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  mounted() {
    if (this.product && !this.product.count) {
      this.product.count = 1;
    }
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
  },
};
</script>

<style>
.product__item {
  flex-basis: 32%;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0 0 22px #c2c2c2;
  margin-bottom: 20px;
  transition: 0.5s;
  background: #fff;
}
.product__item:hover {
  transform: translateY(-10px);
}
.product__img {
  height: 200px;
  display: flex;
  padding: 5px;
}
.product__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product__title {
  height: 50px;
  padding: 10px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e2e2e2;
}
.product__price {
  font-size: 13px;
}
.product__count {
  display: flex;
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
}
.product__count button {
  width: 15%;
  height: 30px;
  font-size: 25px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background: #af886e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product__count input {
  display: block;
  width: 50%;
  height: 30px;
  text-align: center;
  border: 1px solid #e2e2e2;
  padding-left: 5%;
}
.product__cart button {
  padding: 10px 20px;
  border: none;
  margin-bottom: 2px;
  background: #5f4330;
  color: #fff;
  cursor: pointer;
}
.color__red {
  background: #a50505 !important;
}
</style>
