<template>
  <div class="admin__info__wrapper">
    <h2>Полезная информация</h2>
    <div class="admin__info__content">
      <p>Для изменения позиции, необходимо кликнуть дважды по строке в таблице.</p>
      <Progress v-show="progress" />
      <input type="text" class="search__input" v-model="search" placeholder="Поиск..." />
      <table border="1">
        <thead>
          <tr>
            <th>ID статьи</th>
            <th>Дата и время</th>
            <th>Заголовок</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="article_list">
          <tr
            @click="func_select_article(item)"
            v-for="item in article_list.filter((item) => {
              return (
                item.title.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.article.toString().toLowerCase().includes(search.toLowerCase()) ||
                new Date(item.datetime)
                  .toLocaleDateString()
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            })"
            :key="item.article_id"
          >
            <td>{{ item.article_id }}</td>
            <td>
              {{
                new Date(item.datetime).toLocaleDateString() +
                " в " +
                new Date(item.datetime).toLocaleTimeString()
              }}
            </td>
            <td>{{ item.title }}</td>
            <td>
              <button @click="func_delete_article(item)">
                {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="admin__article__add">
        <h3>{{ this.article_selected ? "Изменить запись" : "Добавить запись" }}</h3>
        <button v-if="article_selected" @click="func_clear_article">Отменить</button>
        <form @submit.prevent="func_add_article">
          <input type="text" placeholder="Заголовок" v-model="article_new.title" />
          <vue-editor v-model="article_new.article"></vue-editor>
          <button>{{ this.article_selected ? "Изменить" : "Добавить" }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    Progress,
    VueEditor,
  },
  data() {
    return {
      search: "",
      progress: true,
      article_list: [],
      article_selected: null,
      article_new: {
        title: "",
        article: "",
      },
    };
  },
  methods: {
    func_clear_article() {
      this.article_new.title = this.article_new.article = "";
      this.article_selected = null;
    },
    func_select_article(item) {
      if (item) {
        this.article_selected = item;
        this.article_new.title = item.title;
        this.article_new.article = item.article;
      }
    },
    func_add_article() {
      if (this.article_new.title && this.article_new.article) {
        window
          .sendRequestAdmin({
            num: 11,
            param: {
              title: this.article_new.title,
              article: this.article_new.article,
              article_id: this.article_selected ? this.article_selected.article_id : 0,
            },
          })
          .then(() => {
            this.article_new.title = this.article_new.article = "";
            this.article_selected = null;
            this.func_get_article_list();
          });
      }
    },
    func_get_article_list() {
      window
        .sendRequestAdmin({
          num: 9,
        })
        .then((result) => {
          if (result) {
            this.article_list = result;
            this.progress = false;
          }
        });
    },
    func_delete_article(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 10,
        param: {
          article_id: item.article_id,
          disable: item.disable,
        },
      });
    },
  },
  mounted() {
    this.func_get_article_list();
  },
};
</script>

<style>
.admin__info__content {
  position: relative;
  min-height: 150px;
}
.admin__article__add {
  margin-top: 20px;
}
</style>
