<template>
  <div class="admin__menu__wrapper">
    <ul>
      <li
        :class="{ menu__active: item.selected }"
        @click="func_select_menu(item)"
        v-for="item in menu_list"
        :key="item.id"
      >
        {{ item.value }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu_list: [
        { id: 1, value: "Категории", selected: false },
        { id: 2, value: "Товары", selected: false },
        { id: 3, value: "Полезная информация", selected: false },
        { id: 4, value: "Отзывы", selected: false },
        { id: 5, value: "Обратная связь", selected: false },
        { id: 6, value: "Партнеры", selected: false },
        { id: 7, value: "Заказы", selected: false },
        { id: 8, value: "Галерея", selected: false },
        { id: 9, value: "Доставка", selected: false },
      ],
    };
  },
  methods: {
    func_select_menu(item) {
      if (item) {
        this.menu_list.forEach(function (item) {
          item.selected = false;
        });

        item.selected = true;
        this.$emit("select_menu", item.id);
      }
    },
  },
};
</script>

<style>
.admin__menu__wrapper ul {
  list-style-type: none;
  font-size: 13px;
}
.admin__menu__wrapper ul li {
  padding: 8px;
  border-bottom: 1px solid #c2c2c2;
  transition: 0.5s;
  cursor: pointer;
}
.admin__menu__wrapper ul li:hover {
  background: #5f4330;
  color: #fff;
}
.admin__menu__wrapper ul li.menu__active {
  background: #5f4330 !important;
  color: #fff !important;
}
</style>
