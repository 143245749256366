<template>
  <div class="form__call__wrapper">
    <div class="form__content">
      <h2>Появились вопросы?</h2>
      <h2>Отправьте заявку на бесплатную консультацию</h2>
      <div class="form">
        <div class="form__group">
          <input type="text" placeholder="Ваше имя *" v-model="user_name" />
          <input type="text" placeholder="Номер телефона *" v-model="user_phone" />
        </div>
        <div class="form__group">
          <div class="group">
            <input v-model="policy" type="checkbox" id="policy" />
            <label for="policy">С условиями работы ознакомлен и согласен</label>
          </div>
          <div class="button">
            <button @click="func_submit">Оставить заявку</button>
          </div>
        </div>
        <div class="err" v-show="err">{{ err }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      policy: false,
      user_name: "",
      user_phone: "",
      err: "",
    };
  },
  methods: {
    func_submit() {
      if (this.policy) {
        if (this.user_name && this.user_phone) {
          window
            .sendRequest({
              num: 5,
              param: {
                user_name: this.user_name,
                user_phone: this.user_phone,
              },
            })
            .then((result) => {
              if (result && result.msg) {
                this.err = this.user_name = this.user_phone = "";
                this.err = result.msg;
                setTimeout(() => {
                  this.err = "";
                }, 3000);
              }
            });
        } else {
          this.err = "Заполните все поля";
        }
      } else {
        this.err = "Необходимо согласиться с правилами";
      }
    },
  },
};
</script>

<style>
.form__call__wrapper {
  margin-top: 20px;
  height: 350px;
  width: 100%;
  background: url("/img/47667085_w640_h640_doska-dlya-opalubki.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.form__call__wrapper:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  content: "";
}
.form__content {
  position: relative;
  width: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  height: 100%;
  margin: 0 auto;
}
.form__content h2 {
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}
.form__content .form .form__group {
  display: flex;
  margin-top: 20px;
}
.form__content .form .form__group input {
  padding: 10px;
  flex-basis: 30%;
}
.form__content .form .form__group input:last-child {
  margin-left: 40px;
}
.form__content .form .form__group input:focus {
  outline: none;
}
.form button {
  margin-top: 10px;
  padding: 10px 20px;
  cursor: pointer;

  margin-left: 40px;
}
.form .button {
  flex-basis: 30%;
}
.form .form__group .group {
  display: flex;
  align-items: center;
  flex-basis: 30%;
  justify-content: flex-start;
}
.form .form__group .group input {
  width: 20px;
  height: 20px;
  flex-basis: 20px;
  margin-right: 10px;
}
.form .form__group .group label {
  font-size: 14px;
}
.err {
  margin-top: 20px;
}
</style>
