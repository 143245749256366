<template>
  <div class="cart__wrapper">
    <div class="cart__content">
      <div class="cart__header">
        <img :src="require('@/assets/BasketBlockImg.png')" alt="" />
        <h4>Корзина</h4>
      </div>
      <div class="cart__info">
        <ul>
          <li>
            Товаров: <span>{{ count }}</span> шт.
          </li>
          <li>
            Сумма: <span>{{ Number(summ).toFixed(2) }}</span> руб.
          </li>
        </ul>
        <button
          @click="func_show_order"
          :disabled="!CART_STATE || CART_STATE.length === 0"
        >
          Оформить заказ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["CART_STATE"]),
  },
  data() {
    return {
      summ: 0,
      count: 0,
    };
  },
  watch: {
    CART_STATE() {
      this.func_calc_summ();
      this.func_calc_count();
    },
  },
  methods: {
    func_calc_summ() {
      let summ = 0;
      let cart = [...this.CART_STATE];
      if (cart.length > 0) {
        cart.forEach((item) => {
          summ += Number(item.price) * Number(item.count || 1);
        });
        this.summ = summ;
      } else {
        this.summ = 0;
      }
    },
    func_calc_count() {
      let cart = [...this.CART_STATE];
      if (cart.length > 0) {
        this.count = cart.length;
      } else {
        this.count = 0;
      }
    },
    func_show_order() {
      if (this.$router.history.current.path !== "/order") {
        this.$router.push("/order");
      }
    },
  },
  mounted() {
    this.func_calc_summ();
    this.func_calc_count();
  },
};
</script>

<style>
.cart__wrapper {
  margin-top: 15px;
  margin-right: 15px;
  min-height: 150px;
  box-shadow: 0 0 22px #c2c2c2;
}
.cart__content .cart__header {
  padding: 10px;
  background: #5f4330;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  text-align: right;
}
.cart__content .cart__header img {
  position: absolute;
  left: 10px;
  width: 55px;
}
.cart__info {
  margin-top: 10px;
  padding: 10px;
}
.cart__info ul {
  list-style-type: none;
}
.cart__info ul li {
  margin-bottom: 15px;
}
.cart__info ul li span {
  font-weight: 700;
}
.cart__info button {
  padding: 8px;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
  background: url("/img/wood-board-5395628_1280.jpg");
  color: #fff;
}
.cart__info button:hover:enabled {
  color: #e2e2e2;
  transform: scale(0.98);
}
.cart__info button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
