<template>
  <div class="catalog__menu">
    <h4><img :src="require('@/assets/ProdBlockImg.png')" alt="" /> Категории</h4>
    <nav id="menuVertical">
      <Progress v-show="progress" />
      <ul>
        <li v-for="item in catalog_list" :key="item.category_id">
          <div class="li__name">
            <span @click="func_show_category(item.category_id)">{{
              item.category_name
            }}</span>
            <img
              v-show="item.arr_dop"
              :src="
                require('@/assets/keyboard-right-arrow-button-1_icon-icons.com_72690.png')
              "
            />
          </div>

          <ul>
            <li v-for="item2 in item.arr_dop" :key="item2.category_id">
              <span @click="func_show_category(item2.category_id)">{{
                item2.category_name
              }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      catalog_list: [],
      progress: true,
    };
  },
  methods: {
    func_get_menu() {
      window
        .sendRequest({
          num: 1,
        })
        .then((result) => {
          if (result) {
            this.catalog_list = result;
            this.progress = false;
          }
        });
    },
    func_show_category(category_id) {
      this.$store.commit("CHANGE_CATEGORY", category_id);
      if (this.$router.history.current.path !== "/catalog") {
        this.$router.push("/catalog");
      }
      if (category_id) {
        this.$route.params.category_id = Number(category_id);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  mounted() {
    this.func_get_menu();
  },
};
</script>

<style>
.catalog__menu {
  margin-top: 15px;
  margin-left: 15px;
  min-height: 350px;
  box-shadow: 0 0 22px #c2c2c2;
}
.catalog__menu h4 {
  padding: 10px;
  background: #5f4330;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  text-align: right;
}
.catalog__menu h4 img {
  position: absolute;
  left: 10px;
  width: 80px;
}
#menuVertical {
  width: 100%;
  min-height: 250px;
  position: relative;
}
#menuVertical ul {
  display: block;
  width: 100%;
  padding-top: 10px;
  list-style: none;
}
#menuVertical ul li {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: normal;
  color: #222;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #e2e2e2;
}
#menuVertical span {
  width: 90%;
  height: 100%;
  padding: 10px 15px;
  display: block;
}
#menuVertical ul li img {
  width: 10px;
}
.li__name {
  display: flex;
  align-items: center;
}
#menuVertical ul li:hover {
  background: #c2c2c2;
  color: #fff;
}
#menuVertical ul li ul {
  position: absolute;
  top: 0px;
  left: 100%;
  display: none;
  width: auto;
  padding: 0;
  background: #a37046;
  transition: 0.5s;
  z-index: 9999999;
}
#menuVertical ul li:hover ul {
  display: block;
}
#menuVertical ul li ul li {
  white-space: nowrap;
  text-transform: none;
  color: #fff;
}
#menuVertical ul li ul li:hover {
  background: #5f4330;
  color: #fff;
}
</style>
