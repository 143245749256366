<template>
  <div class="admin__partners__wrapper">
    <div class="admin__partners__title">
      <h2>Партнеры</h2>
    </div>
    <div class="admin__partners__content">
      <Progress v-show="progress" />
      <p>Для изменения позиции, необходимо кликнуть дважды по строке в таблице.</p>
      <input type="text" class="search__input" v-model="search" placeholder="Поиск..." />
      <table border="1">
        <thead>
          <tr>
            <th>ID партнера</th>
            <th>Изображение</th>
            <th>Название компании</th>
            <th>Ссылка</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="partners_list">
          <tr
            v-for="item in partners_list.filter((item) => {
              return item.title.toLowerCase().includes(search.toLowerCase());
            })"
            :key="item.partner_id"
            @click="func_select_item(item)"
          >
            <td>{{ item.partner_id }}</td>
            <td><img :src="'img/partners/' + item.img" alt="" /></td>
            <td>
              {{ item.title }}
            </td>
            <td>
              {{ item.link }}
            </td>

            <td>
              <button @click="func_delete_partner(item)">
                {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="admin__add__partner">
        <h4>{{ selected_partner ? "Изменить" : "Добавить" }} партнера</h4>
        <button v-if="selected_partner" @click="func_clear_selected_partner">
          Сбросить
        </button>
        <form @submit.prevent="func_add_partner">
          <input
            type="text"
            placeholder="Название компании"
            required
            v-model="form.title"
          />
          <input type="text" placeholder="Ссылка" required v-model="form.link" />
          <input
            v-if="!selected_partner"
            type="file"
            ref="file"
            v-on:change="func_file_upload()"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            required
          />
          <input
            type="file"
            ref="file"
            v-on:change="func_file_upload()"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            v-else
          />
          <button>{{ selected_partner ? "Изменить" : "Добавить" }}</button>
          <p v-show="selected_partner">
            При изменение позиции если фото не требуется изменять, файл выбирать не
            следует, оставить пустым.
          </p>
          <p v-show="error">{{ error }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      progress: true,
      partners_list: [],
      search: "",
      form: {
        title: "",
        link: "",
        file: null,
      },
      error: "",
      selected_partner: null,
    };
  },
  methods: {
    func_clear_selected_partner() {
      this.form.title = this.form.link = "";
      this.form.file = null;
      this.selected_partner = null;

      this.func_clear_file();
    },
    func_select_item(item) {
      if (item) this.selected_partner = item;
      this.form.title = this.selected_partner.title;
      this.form.link = this.selected_partner.link;
    },
    func_file_upload() {
      if (this.$refs.file) {
        this.form.file = this.$refs.file.files[0];
      }
    },
    func_get_partners() {
      window
        .sendRequestAdmin({
          num: 6,
        })
        .then((result) => {
          if (result) {
            this.partners_list = result;
            this.progress = false;
          }
        });
    },
    func_delete_partner(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 7,
        param: {
          partner_id: item.partner_id,
          disable: item.disable,
        },
      });
    },
    func_add_partner() {
      if (this.form.title && this.form.link) {
        window
          .sendRequestAdmin({
            num: 8,
            param: {
              title: this.form.title,
              link: this.form.link,
              img: this.form.file,
              img_path: "partners",
              partner_id:
                this.selected_partner !== null ? this.selected_partner.partner_id : 0,
            },
          })
          .then(() => {
            this.form.link = this.form.title = "";
            this.form.file = null;

            this.func_clear_file();

            this.func_get_partners();
          });
      } else {
        this.error = "Заполните все поля";
      }
    },
    func_clear_file() {
      let file = this.$refs.file;
      file.type = "text";
      file.type = "file";
    },
  },
  mounted() {
    this.func_get_partners();
  },
};
</script>

<style>
.admin__partners__content {
  margin-top: 15px;
  position: relative;
  min-height: 150px;
}
.admin__partners__content table {
  margin-top: 10px;
  word-break: normal;
  border: 1px solid #222;
  width: 100%;
}
.admin__partners__content table td:not(.input_table),
.admin__partners__content table th {
  padding: 5px;
  font-size: 12px;
}
.admin__partners__content table td textarea {
  width: 100%;
  height: 100%;
}
.admin__partners__content table button {
  width: 100px;
}
.admin__partners__content table td img {
  width: 100px;
}
.admin__partners__content table tbody tr {
  transition: 0.5s;
  cursor: pointer;
}
.admin__partners__content table tbody tr:hover {
  background: #e2e2e2;
}
.admin__add__partner {
  margin-top: 15px;
}
</style>
