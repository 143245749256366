<template>
  <div class="index__wrapper">
    <h1>Продажа пиломатериалов в Москве</h1>
    <p>
      <span class="color_brown">Лесопромышленная компания "Пром-Лес"</span> занимается
      поставкой продукции для внешней и внутренней отделки домов, бань, саун, беседок и
      террас.
    </p>
    <p>
      Вся продукция изготовлена из натуральных и экологически чистых древесных пород (ель,
      сосна, лиственница, осина, ольха, и т.д.), характеризуется долговечностью и
      устойчивостью к перепадам температур, влаге и деформации.
    </p>
    <div class="index__products__top">
      <div class="top__products__header">
        <h4>Продукция</h4>
      </div>
      <div class="products__top__list">
        <Progress v-show="progress" />
        <div class="category__items">
          <div
            class="category__item"
            v-for="item in category_list"
            :key="item.category_id"
            @click="func_show_catalog(item.category_id)"
          >
            <div class="category__img">
              <img :src="'/img/category/' + item.img" :alt="item.category_name" />
            </div>
            <div class="category__price">
              <p>
                от
                {{
                  item.min_price
                    ? Number(item.min_price).toFixed(2)
                    : Number(0).toFixed(2)
                }}
                руб.
              </p>
            </div>
            <div class="category__name">
              {{ item.category_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index__info">
      <p>
        Мы – «Пром-Лес», лесопромышленная компания, которая была основана еще в 1996 году.
        Именно с того года мы начали свое производство различных изделий из сосны,
        лиственницы и кедра, а сегодня, благодаря нашей стойкости, ответственности и любви
        к своему делу, «Пром-Лес» является лидером по изготовлению и продаже
        лесопромышленной продукции.
      </p>
      <p>
        Сейчас наша компания – это огромное предприятие, имеющее свои производственные
        помещения, лесопильный и столярный цеха, а также свой собственный автопарк, что
        позволяет нам производить собственную продукцию высокого качества и при этом
        оперативно доставлять товар в Москву, Московскую область, а также другие регионы
        Российской Федерации. Однако вы всегда можете оформить самовывоз и самостоятельно
        забрать свой заказ, проверив качество товара.
      </p>
      <p>
        Что же касается нашей продукции, то список ее весьма обширен. Мы производим:
        блок-хаус, обрезные и строганые доски, европол, евровагонку, имитацию бруса, всех
        видов фанеру, клееный и строганый брус, а также различные изделия для бани и
        сауны. И мы не собираемся останавливаться на достигнутом: у нас есть новейшее
        деревообрабатывающее оборудование, а также усовершенствованное старое, которые
        позволяют нам регулярно расширять и улучшать список производимой нами продукции.
      </p>
      <p>
        Мы всегда следим за своей репутацией и стараемся совершенствоваться каждый день,
        улучшая наши изделия. На сегодняшний день «Пром-Лес» – это высокое качество и
        низкие цены, благодаря которым каждый может приобрести нашу лесопромышленную
        продукцию и убедиться в прочности товаров, а также оценить все их преимущества.
        Такой подход позволил нам получить репутацию надежного партнера и начать
        сотрудничать со многими крупными предприятиями.
      </p>
      <p>
        Наша компания продолжает расти и развиваться, мы регулярно рассматриваем новые
        предложения по сотрудничеству и стремимся стать лучшими в своем сегменте!
      </p>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      category_list: [],
      progress: true,
    };
  },
  methods: {
    func_show_catalog(category_id) {
      this.$store.commit("CHANGE_CATEGORY", category_id);
      if (this.$router.history.current.path !== "/catalog") {
        this.$router.push("/catalog");
      }
      if (category_id) {
        this.$route.params.category_id = Number(category_id);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    func_get_top_products() {
      window
        .sendRequest({
          num: 2,
        })
        .then((result) => {
          if (result && result.category_list) {
            this.category_list = result.category_list;
            this.progress = false;
          }
        });
    },
  },
  mounted() {
    this.func_get_top_products();
  },
};
</script>

<style>
.index__wrapper h1 {
  font-size: 20px;
  text-align: center;
}
.index__wrapper p {
  margin-top: 15px;
}
.color_brown {
  color: #a37046;
  font-weight: 700;
}
.index__products__top {
  margin: 25px 0;
}
.top__products__header {
  background: #4a3425;
  color: #fff;
  padding: 10px;
}
.products__top__list {
  min-height: 250px;
  position: relative;
}
.category__items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 5px;
}
.category__item {
  flex-basis: 30%;
  cursor: pointer;
  border: 1px solid #e2e2e2;
  margin-bottom: 20px;
  position: relative;
  transition: 0.3s;
}
.category__item:hover {
  transform: scale(0.97);
}
.category__img {
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
}
.category__img:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}
.category__item:hover .category__img:before {
  background: rgba(0, 0, 0, 0);
}
.category__img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
}
.category__price {
  position: absolute;
  width: 60%;
  background: #6b452b;
  bottom: 10px;
  right: 0;
  color: #fff;
  padding: 5px;
}
.category__name {
  position: absolute;
  width: 60%;
  background: #351b05;
  top: 10px;
  left: 0;
  color: #fff;
  padding: 5px;
}
.category__price p {
  margin: 0;
  margin-top: 5px;
  text-align: center;
}
</style>
