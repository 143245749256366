<template>
  <div class="admin__answer__wrapper">
    <h2>Отзывы</h2>
    <div class="admin__answer__content">
      <Progress v-show="progress" />
      <input type="text" class="search__input" v-model="search" placeholder="Поиск..." />
      <table border="1">
        <thead>
          <tr>
            <th>ID отзыва</th>
            <th>Дата и время</th>
            <th>Имя пользователя</th>
            <th>Email пользователя</th>
            <th>Компания</th>
            <th>Отзыв</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in answer_list.filter((item) => {
              return (
                item.user_name.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.user_email.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.company_name
                  .toString()
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.answer.toString().toLowerCase().includes(search.toLowerCase()) ||
                new Date(item.datetime)
                  .toLocaleDateString()
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            })"
            :key="item.answer_id"
          >
            <td>{{ item.answer_id }}</td>
            <td>
              {{
                new Date(item.datetime).toLocaleDateString() +
                " в " +
                new Date(item.datetime).toLocaleTimeString()
              }}
            </td>
            <td>{{ item.user_name }}</td>
            <td>{{ item.user_email }}</td>
            <td>{{ item.company_name }}</td>
            <td :title="item.answer"><span>Наведите для просмотра отзыва</span></td>
            <td>
              <button @click="func_delete_answer(item)">
                {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      answer_list: [],
      progress: true,
      search: "",
    };
  },
  methods: {
    func_get_answers() {
      window
        .sendRequestAdmin({
          num: 1,
        })
        .then((result) => {
          if (result) {
            this.answer_list = result;
            this.progress = false;
          }
        });
    },
    func_delete_answer(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 2,
        param: {
          answer_id: item.answer_id,
          disable: item.disable,
        },
      });
    },
  },
  mounted() {
    this.func_get_answers();
  },
};
</script>

<style>
.admin__answer__content {
  position: relative;
  min-height: 100px;
}
</style>
