<template>
  <div class="modal__wrapper" v-show="MODAL_STATE">
    <div class="modal">
      <div class="modal__title">
        <h4>Обратный звонок</h4>
        <div class="modal__cross" @click="func_close_modal">X</div>
      </div>
      <div class="modal__body">
        <form @submit.prevent="func_submit_form">
          <input type="text" placeholder="Ваше имя *" required v-model="user_name" />
          <input
            type="text"
            placeholder="Номер телефона *"
            required
            v-model="user_phone"
          />
          <button>Отправить</button>
          <div class="err" v-show="err">{{ err }}</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["MODAL_STATE"]),
  },
  data() {
    return {
      user_name: "",
      user_phone: "",
      err: "",
    };
  },
  methods: {
    func_close_modal() {
      this.$store.commit("CHANGE_MODAL", false);
    },
    func_submit_form() {
      if (this.user_name && this.user_phone) {
        window
          .sendRequest({
            num: 5,
            param: {
              user_name: this.user_name,
              user_phone: this.user_phone,
            },
          })
          .then((result) => {
            if (result && result.msg) {
              this.err = this.user_name = this.user_phone = "";
              this.err = result.msg;
              setTimeout(() => {
                this.err = "";
                this.func_close_modal();
              }, 3000);
            }
          });
      } else {
        this.err = "Заполните все поля";
      }
    },
  },
};
</script>

<style>
.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  transition: 0.3s all;
}
.modal__wrapper .modal {
  width: 100%;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  box-shadow: 0 3px 10px -0.5px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 30px;
  border-radius: 3px;
  background-color: #fff;
  transition: 0.3s all;
  padding-top: 10px;
}
.modal .modal__cross {
  color: #444;
  width: 25px;
  height: 20px;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  transition: 0.5s;
}
.modal .modal__cross:hover {
  background: #5f4330;
  color: #fff;
}
.modal__title {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal__title h4 {
  flex-basis: 90%;
  padding-left: 10%;
}
.modal__body {
  margin-top: 15px;
}
.modal__body input {
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #5f4330;
  border-radius: 5px;
}
.modal__body input:focus {
  outline: none;
}
.modal__body button {
  margin-top: 10px;
  background: #5f4330;
  color: #fff;
  padding: 10px;
  border: 0;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0 0 22px #c2c2c2;
  cursor: pointer;
  transition: color 0.3s;
}
.modal__body button:hover {
  color: #e2e2e2;
  transform: scale(0.98);
}
</style>
