<template>
  <div class="admin__gallery__wrapper">
    <h2>Галерея</h2>
    <div class="admin__gallery__content">
      <Progress v-show="progress" />
      <div class="add__gallery">
        <h4>Добавить изображение</h4>
        <form @submit.prevent="func_add_img">
          <input
            required
            type="file"
            ref="file"
            v-on:change="func_file_upload()"
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
          />
          <button>Сохранить</button>
        </form>
      </div>
      <table border="1">
        <thead>
          <tr>
            <th>Изображение</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in gallery_list" :key="item.gallery_id">
            <td>
              <div class="gallery__img">
                <img :src="'/img/gallery/' + item.img" />
              </div>
            </td>
            <td>
              <button @click="func_disable(item)">
                {{ Number(item.disable) ? "Восстановить" : "Удалить" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      gallery_list: [],
      progress: true,
      file: null,
    };
  },
  methods: {
    func_clear_file() {
      let file = this.$refs.file;
      file.type = "text";
      file.type = "file";
    },
    func_file_upload() {
      if (this.$refs.file) {
        this.file = this.$refs.file.files[0];
      }
    },
    func_add_img() {
      window
        .sendRequestAdmin({
          num: 25,
          param: {
            img: this.file,
            img_path: "gallery",
          },
        })
        .then(() => {
          this.func_clear_file();
          this.func_get_gallery();
        });
    },
    func_get_gallery() {
      window
        .sendRequestAdmin({
          num: 23,
        })
        .then((result) => {
          if (result) {
            this.gallery_list = result;
            this.progress = false;
          }
        });
    },
    func_disable(item) {
      if (item) {
        item.disable = !Number(item.disable);
      }
      window.sendRequestAdmin({
        num: 24,
        param: {
          gallery_id: item.gallery_id,
          disable: item.disable,
        },
      });
    },
  },
  mounted() {
    this.func_get_gallery();
  },
};
</script>

<style>
.admin__gallery__content {
  margin-top: 20px;
  position: relative;
  min-height: 150px;
}
.admin__gallery__content table .gallery__img {
  width: 100%;
  height: 250px;
  display: flex;
}
.admin__gallery__content table .gallery__img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
}
</style>
