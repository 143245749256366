import Vue from 'vue'
import Router from 'vue-router'

import Index from '../components/page/v-index';
import About from '../components/page/v-about';
import Catalog from '../components/page/v-catalog';
import Answers from '../components/page/v-answers';
import Search from '../components/page/v-search';
import Payment from '../components/page/v-payment';
import Delivery from '../components/page/v-delivery';
import Contact from '../components/page/v-contact';
import Info from '../components/page/v-info';
import Product from '../components/page/v-product';
import Order from '../components/page/v-order';
import Admin from '../components/page/v-admin';
import Gallery from '../components/page/v-gallery';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        component: Index
    }, {
        path: '/about',
        component: About
    }, {
        path: '/catalog',
        component: Catalog,
        props: true
    }, {
        path: '/answers',
        component: Answers
    }, {
        path: '/payment',
        component: Payment
    }, {
        path: '/delivery',
        component: Delivery
    }, {
        path: '/contact',
        component: Contact
    }, {
        path: '/info',
        component: Info,
        props: true
    }, {
        path: '/search',
        component: Search,
        props: true
    }, {
        path: '/product',
        component: Product,
        props: true
    }, {
        path: '/order',
        component: Order,
        props: true
    }, {
        path: '/admin',
        component: Admin
    }, {
        path: '/gallery',
        component: Gallery
    }]
})

export default router;