var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product__item"},[_c('div',{staticClass:"product__img",on:{"click":_vm.func_show_product}},[_c('img',{attrs:{"src":_vm.product.img
          ? '/img/products/' + _vm.product.img
          : require('@/assets/0259a878942a099e46261bbd49e85608.jpg'),"alt":""}})]),_c('div',{staticClass:"product__title",attrs:{"title":_vm.product.product_name +
      ' ' +
      _vm.product.size +
      (_vm.product.lgth ? 'x' + _vm.product.lgth : '') +
      ' ' +
      _vm.product.sort},on:{"click":_vm.func_show_product}},[_vm._v(" "+_vm._s(( _vm.product.product_name + " " + _vm.product.size + (_vm.product.lgth ? "x" + _vm.product.lgth : "") + " " + _vm.product.sort ).length > 300 ? ( _vm.product.product_name + " " + _vm.product.size + (_vm.product.lgth ? "x" + _vm.product.lgth : "") + " " + _vm.product.sort ).substr(0, 300) : _vm.product.product_name + " " + _vm.product.size + (_vm.product.lgth ? "x" + _vm.product.lgth : "") + " " + _vm.product.sort)+" ")]),_c('div',{staticClass:"product__price"},[_vm._v(" "+_vm._s((_vm.product.price ? Number(_vm.product.price).toFixed(2) : 0) + " руб. за " + _vm.product.izm)+" ")]),_c('div',{staticClass:"product__count"},[_c('button',{on:{"click":function($event){return _vm.func_count(false)}}},[_vm._v("-")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.count),expression:"product.count"}],attrs:{"type":"number"},domProps:{"value":(_vm.product.count)},on:{"change":_vm.func_check_input,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.product, "count", $event.target.value)}}}),_c('button',{on:{"click":function($event){return _vm.func_count(true)}}},[_vm._v("+")])]),_c('div',{staticClass:"product__cart"},[_c('button',{class:{ color__red: _vm.func_check_cart() },on:{"click":function($event){_vm.func_add_to_cart(_vm.func_check_cart())}}},[_vm._v(" "+_vm._s(_vm.func_check_cart() ? "Убрать из корзины" : "В корзину")+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }