import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import store from './store.js'
import router from '@/router/router.js'
import VueEditor from 'vue2-editor'
import Quill from 'vue2-editor'
import request from '@/request.js';

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    request,
    axios,
    store,
    router,
    VueEditor,
    Quill
}).$mount('#app')