<template>
  <div class="admin__order__wrapper">
    <h2>Заказы</h2>
    <div class="admin__order__content">
      <Progress v-show="progress" />
      <div class="order__list" v-if="!selected_order">
        <input
          type="text"
          class="search__input"
          v-model="search"
          placeholder="Поиск..."
        />
        <table border="1">
          <thead>
            <tr>
              <th>ID заказа</th>
              <th>Пользователь</th>
              <th>Телефон</th>
              <th>Email</th>
              <th>Сумма</th>
              <th>Дата и время</th>
              <th>Комментарий</th>
              <th>Адрес доставки</th>
              <th>Дата доставки</th>
            </tr>
          </thead>
          <tbody v-if="order_list">
            <tr
              @click="func_select_order(item)"
              v-for="item in order_list.filter((item) => {
                return (
                  item.user_name
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.phone.toString().toLowerCase().includes(search.toLowerCase()) ||
                  item.email.toString().toLowerCase().includes(search.toLowerCase()) ||
                  item.price.toString().toLowerCase().includes(search.toLowerCase()) ||
                  new Date(item.datetime)
                    .toLocaleDateString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
                );
              })"
              :key="item.order_title_id"
            >
              <td>{{ item.order_title_id }}</td>
              <td>{{ item.user_name }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.price }}</td>
              <td>
                {{
                  new Date(item.datetime).toLocaleDateString() +
                  " в " +
                  new Date(item.datetime).toLocaleTimeString()
                }}
              </td>
              <td>{{ item.comment }}</td>
              <td>{{ item.adress }}</td>
              <td>{{ item.datetime_dostavka }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="order__data" v-else>
        <button @click="func_select_order(null)">Назад</button>
        <h4>Товары</h4>
        <input
          type="text"
          class="search__input"
          v-model="search2"
          placeholder="Поиск..."
        />
        <table border="1">
          <thead>
            <tr>
              <th>ID строки</th>
              <th>Наименование</th>
              <th>Кол-во</th>
              <th>Цена / Сумма</th>
            </tr>
          </thead>
          <tbody v-if="selected_order.order_data">
            <tr
              v-for="item in selected_order.order_data.filter((item) => {
                return (
                  item.product_name
                    .toString()
                    .toLowerCase()
                    .includes(search2.toLowerCase()) ||
                  item.size.toString().toLowerCase().includes(search2.toLowerCase()) ||
                  item.price.toString().toLowerCase().includes(search2.toLowerCase())
                );
              })"
              :key="item.order_data_id"
            >
              <td>{{ item.order_data_id }}</td>
              <td>
                {{ item.product_name + " " + item.size }}
              </td>
              <td>{{ item.count }}</td>
              <td>
                {{
                  Number(item.price).toFixed(2) +
                  " / " +
                  Number(Number(item.price) * Number(item.count)).toFixed(2)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      progress: true,
      order_list: [],
      search: "",
      selected_order: null,
      search2: "",
    };
  },
  methods: {
    func_select_order(item) {
      this.selected_order = item;
    },
    func_get_orders() {
      window
        .sendRequestAdmin({
          num: 21,
        })
        .then((result) => {
          if (result) {
            this.order_list = result;
            this.progress = false;
          }
        });
    },
  },
  mounted() {
    this.func_get_orders();
  },
};
</script>

<style>
.admin__order__content {
  margin-top: 15px;
  min-height: 150px;
  position: relative;
}
.order__data h4 {
  margin-top: 15px;
}
</style>
